import {
  FreezerService,
  _,
  bind,
  managedAjaxUtil,
  IAjaxState,
  Helpers,
  NullableOptional
} from "$Imports/Imports";

import {
  yup,
  SchemaOf,
  ValidationError
} from "$Shared/imports/Yup";

import {
  QuoteFreight,
  CustomerQuoteApiFactory,
  CustomerQuoteFreight,
  QuoteCalculatedRateRateLevelFactorEnum,
  Commodity,
  CalculateRatingVariableResult
} from "$Generated/api";

import {
  QuoteEntryService
} from "$State/QuoteEntryFreezerService";

import {
  ErrorService
} from "$State/ErrorFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

export interface FreightUpdateRowData {
  indexBeingUpdated?: number;
  isRowBeingEdited?: boolean;
}

export interface FreightTotalData {
  totalNumOfPieces?: number;
  totalWeight?: number;
  totalLength?: number;
  maxWidth?: number;
  maxHeight?: number;
  ratingVariable?: QuoteCalculatedRateRateLevelFactorEnum;
  ratingVariableCalculatedAmount?: number;
  canRatingVariableBeOverriden?: boolean;
  isOverdimensional?: boolean;
  isWeightOverdimensional?: boolean;
}

interface IQuoteFreightState {
  isFreightModalOpen: boolean,
  currentQuoteFreight: CustomerQuoteFreight[],
  freightTotalData: FreightTotalData,
  originalFreightTotalData: FreightTotalData,
  quoteFreightValidationErrors: ValidationError | null,
  rateVariableValidationErrors: ValidationError | null,
  updatedFreightRowData: FreightUpdateRowData;
  totalNumberOfPieces: number,
  addEditQuoteFreight: QuoteFreight;
  calculateRatingVariableResults: IAjaxState<CalculateRatingVariableResult>;
}

const initialState = {
  isFreightModalOpen: false,
  quoteFreight: [],
  currentQuoteFreight: [],
  freightTotalData: {},
  originalFreightTotalData: {},
  quoteFreightValidationErrors: null,
  rateVariableValidationErrors: null,
  updatedFreightRowData: {},
  addEditQuoteFreight: {},
  totalNumberOfPieces: 0,
  calculateRatingVariableResults: managedAjaxUtil.createInitialState()
} as IQuoteFreightState

const InjectedPropName = "QuoteFreightService";

const CustomerQuoteFreightSchema: SchemaOf<CustomerQuoteFreight> = yup.object({
  id: yup.number().notRequired(),
  customerQuoteId: yup.number().notRequired(),
  commodityId: yup.number().required("Commodity is required."),
  width: yup.number()
    .typeError("Width is required.")
    .required("Width is required.")
    .min(1, "Width must be greater than 0."),
  height: yup.number()
    .typeError("Height is required.")
    .required("Height is required.")
    .min(1, "Height must be greater than 0."),
  length: yup.number()
    .typeError("Length is required.")
    .required("Length is required.")
    .min(1, "Length must be greater than 0."),
  weight: yup.number()
    .typeError("Weight is required.")
    .required("Weight is required.")
    .min(1, "Weight must be greater than 0."),
  isStackable: yup.boolean().notRequired(),
  isSideBySide: yup.boolean().notRequired(),
  numberOfPieces: yup.number().required("Number of pieces is required")
    .min(1, "There must be at least one piece of each commodity entered.").transform((value: any) => value && !isNaN(value.valueOf()) ? value : undefined),
  createdOn: yup.date().notRequired(),
  commodity: yup.object().notRequired(),
  customerQuote: yup.mixed().notRequired().nullable(true)
});

const RateVariableValidationSchema: SchemaOf<NullableOptional<FreightTotalData>> = yup.object({
  ratingVariable: yup.mixed<QuoteCalculatedRateRateLevelFactorEnum>().notRequired(),
  totalLength: yup.number().notRequired(),
  totalNumOfPieces: yup.number().notRequired(),
  totalWeight: yup.number().notRequired(),
  maxHeight: yup.number().notRequired(),
  maxWidth: yup.number().notRequired(),
  ratingVariableCalculatedAmount: yup.number().notRequired(),
  canRatingVariableBeOverriden: yup.boolean().notRequired(),
  isOverdimensional: yup.boolean().notRequired(),
  isWeightOverdimensional: yup.boolean().notRequired()
});

class QuoteFreightFreezerService extends FreezerService<IQuoteFreightState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  public openFreightModal() {
    const {
      freightTotalData
    } = this.freezer.get().toJS();

    const {
      customerQuote
    } = QuoteEntryService.freezer.get().toJS();

    let currentQuoteFreight = customerQuote.customerQuoteFreights ?? [];

    this.freezer.get().set({
      currentQuoteFreight: currentQuoteFreight,
      isFreightModalOpen: true,
      originalFreightTotalData: freightTotalData
    });

  }

  public async saveFreightModal() {

    const {
      currentQuoteFreight,
      freightTotalData
    } = this.freezer.get().toJS();
    
    const errors = await validateSchema(RateVariableValidationSchema, freightTotalData, {
      abortEarly: false
    });

    this.freezer.get().set({ rateVariableValidationErrors: errors });

    if (errors && currentQuoteFreight.length !== 0) {
      return;
    }

    QuoteEntryService.updateCustomerQuote({ customerQuoteFreights: currentQuoteFreight }, true);

    this.freezer.get().set({
      isFreightModalOpen: false,
      quoteFreightValidationErrors: null,
      rateVariableValidationErrors: null,
      totalNumberOfPieces: freightTotalData.totalNumOfPieces ?? 0,
      currentQuoteFreight: currentQuoteFreight,
      updatedFreightRowData: {},
      addEditQuoteFreight: {}
    });

  }

  public cancelFreightModal() {
    const {
      originalFreightTotalData
    } = this.freezer.get().toJS();

    const {
      customerQuote
    } = QuoteEntryService.freezer.get().toJS();

    let currentQuoteFreight = customerQuote.customerQuoteFreights ?? [];

    //when cancelling - close the modal and reset the value of the
    //currentQuoteFreight variable to its previous value
    this.freezer.get().set({
      isFreightModalOpen: false,
      currentQuoteFreight: currentQuoteFreight,
      quoteFreightValidationErrors: null,
      rateVariableValidationErrors: null,
      updatedFreightRowData: {},
      addEditQuoteFreight: {},
      freightTotalData: originalFreightTotalData
    });
  }

  public async onUpdateQuoteFreightRow(overdimensionalRulesetId: number = 0) {
    const {
      updatedFreightRowData,
      currentQuoteFreight,
      addEditQuoteFreight
    } = this.freezer.get().toJS();

    const errors = await this._isValidFreight();
    if (errors) {
      return;
    }

    const indexToUpdate = updatedFreightRowData.indexBeingUpdated;

    if (!Helpers.isNullOrUndefined(indexToUpdate)) {
      currentQuoteFreight[indexToUpdate!] = addEditQuoteFreight;
      this.updateAddEditQuoteFreight({});
    }

    this.calculateFreightTotalResults(currentQuoteFreight, overdimensionalRulesetId);

    this.freezer.get().set({
      currentQuoteFreight: currentQuoteFreight,
      updatedFreightRowData: {},
      addEditQuoteFreight: {},
      quoteFreightValidationErrors: null
    });
  }

  public updateAddEditQuoteFreight(quoteFreight: Partial<CustomerQuoteFreight>) {
    this.freezer.get().addEditQuoteFreight.set(quoteFreight);
  }

  public onQuoteFreightEdit(activeCommodities: Commodity[], quoteFreightIndex: number) {
    const {
      currentQuoteFreight
    } = this.freezer.get().toJS()

    const freightPieceToEdit = currentQuoteFreight[quoteFreightIndex];
    const freightCommodity = activeCommodities.find(c => c.id === freightPieceToEdit.commodityId);

    if (!freightCommodity) {
      freightPieceToEdit.commodity = undefined;
      freightPieceToEdit.commodityId = undefined;
    }

    if (!freightPieceToEdit.commodity) {
      freightPieceToEdit.commodity = freightCommodity;
    }

    this.freezer.get().set({
      updatedFreightRowData: {
        indexBeingUpdated: quoteFreightIndex,
        isRowBeingEdited: true,
      },
      quoteFreightValidationErrors: null,
      addEditQuoteFreight: freightPieceToEdit
    });
  }

  public async addNewFreightCommodity(overdimensionalRulesetId: number = 0) {
    const {
      currentQuoteFreight,
      addEditQuoteFreight
    } = this.freezer.get().toJS();

    const errors = await this._isValidFreight();
    if (errors) {
      return;
    }

    if (currentQuoteFreight) {
      currentQuoteFreight.push(addEditQuoteFreight);
      this.calculateFreightTotalResults(currentQuoteFreight, overdimensionalRulesetId);

      this.freezer.get().set({
        currentQuoteFreight: currentQuoteFreight,
        addEditQuoteFreight: {}
      });
    }
  }

  public removeQuoteFreight(quoteFreightIndex: number) {
    const {
      currentQuoteFreight,
      updatedFreightRowData,
      addEditQuoteFreight
    } = this.freezer.get().toJS();

    if (currentQuoteFreight && currentQuoteFreight.hasOwnProperty(quoteFreightIndex)) {

      currentQuoteFreight.splice(quoteFreightIndex, 1);
      this.calculateFreightTotalResults(currentQuoteFreight);

      let newUpdatedFreightRowData = updatedFreightRowData;
      const indexBeingUpdated = updatedFreightRowData.indexBeingUpdated;

      var newAddEditQuoteFreight: CustomerQuoteFreight = addEditQuoteFreight;
      if (indexBeingUpdated === quoteFreightIndex) {
        newAddEditQuoteFreight = {};
        newUpdatedFreightRowData = {};
      } else if (indexBeingUpdated && quoteFreightIndex < indexBeingUpdated) {
        newUpdatedFreightRowData.indexBeingUpdated = (indexBeingUpdated - 1);
      }

      this.freezer.get().set({
        currentQuoteFreight: currentQuoteFreight,
        updatedFreightRowData: newUpdatedFreightRowData,
        addEditQuoteFreight: newAddEditQuoteFreight
      });
    }
  }

  public updateFreightTotalData(newFreightTotalData: Partial<FreightTotalData>) {
    this.freezer.get().freightTotalData.set(newFreightTotalData);
  }

  public async calculateFreightTotalResults(currentQuoteFreight: CustomerQuoteFreight[], overdimensionalRulesetId: number = 0) {
    if (!currentQuoteFreight.length || !overdimensionalRulesetId) {
      this.freezer.get().set({ freightTotalData: {} });
      return;
    }

    let newFreightTotal: Partial<FreightTotalData> = {};

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "calculateRatingVariableResults",
      params: {
        body: {
          quoteFreight: currentQuoteFreight,
          overdimensionalRulesetId: overdimensionalRulesetId
        }
      },
      onExecute: (apiOptions, param, options) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.getCalculatedRatingVariable(param);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch rating variable");
      },
      onOk: (data: CalculateRatingVariableResult) => {
        const isOverdimensional = (data.ratingVariableType === "Overdimensional");
        const canOverride = (data.ratingVariableType === "Length");

        newFreightTotal = {
          totalNumOfPieces: currentQuoteFreight.map(x => x.numberOfPieces).reduce((acc, cur) => (acc ?? 0) + (cur ?? 0)),
          canRatingVariableBeOverriden: canOverride,
          isOverdimensional: isOverdimensional,
          ratingVariable: data.ratingVariableType,
          ratingVariableCalculatedAmount: data.ratingVariable,
          totalLength: data.totalLength,
          totalWeight: data.totalWeight,
          isWeightOverdimensional: data.isWeightOverdimensional
        };

        QuoteEntryService.updateCustomerQuote({ rateVariableFactor: data.ratingVariableType, rateVariable: data.ratingVariable });

        if (data.error) {
          ErrorService.pushErrorMessage(data.error, "high");
        }
      }
    });

    this.freezer.get().freightTotalData.set(newFreightTotal);
  }

  private async _isValidFreight(): Promise<ValidationError | null> {
    const { addEditQuoteFreight } = this.freezer.get().toJS();
    const errors = await validateSchema(CustomerQuoteFreightSchema, addEditQuoteFreight, {
      abortEarly: false
    });

    this.freezer.get().set({ quoteFreightValidationErrors: errors });

    if (errors) {
      return errors;
    }

    return null;
  }
}

export const QuoteFreightService = new QuoteFreightFreezerService();
export type IQuoteFreightServiceInjectedProps = ReturnType<QuoteFreightFreezerService["getPropsForInjection"]>;