import {
  managedAjaxUtil,
  FreezerService,
  bind,
  _,
  IAjaxState,
  moment,
  Helpers
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  CustomerQuote,
  CustomerQuoteFreight,
  CustomerQuoteApiFactory,
  CustomerPortalRateEngineResult,
  RateCalculationParams,
  RateCalculationAddressPair,
  CustomerQuoteQuoteStatusEnum,
  CustomerQuoteVM,
  Place,
  OtherQuoteInfoVM,
  Commodity
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  CustomerQuoteSchema,
  DeclinedReasonSchema
} from "./QuoteEntryValidation";

import {
  QuoteFreightService
} from "./QuoteFreightFreezerService";

import {
  CommodityService
} from "./CommodityFreezerService";

import {
  validateQuoteFilter
} from "$Utilities/customerQuoteUtil";

import {
  CustomerDetailService
} from "./CustomerDetailFreezerService";

import {
  QuoteZipCodeMileageService
} from "./QuoteZipCodeMileageFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import { jsDateConverter } from "$Shared/utilities/dateTimeUtil";

const InjectedPropName = "QuoteEntryService";

interface IQuoteEntryState {
  customerQuote: CustomerQuote;
  shadowQuote: CustomerQuote;
  quoteValidationErrors: ValidationError | null;
  isPrintQuoteViewOpen: boolean;
  shipmentNotes: string | undefined;
  quoteSaveResults: IAjaxState<CustomerQuote>;
  quoteFetchResults: IAjaxState<CustomerQuote>;
  quoteStatusSaveResults: IAjaxState<CustomerQuote>;
  quoteOtherInfoSaveResults: IAjaxState<CustomerQuote>;
  quoteContactSaveResults: IAjaxState<CustomerQuote>;
  rateFetchResults: IAjaxState<CustomerPortalRateEngineResult>;
  tripAnalyzerResults: IAjaxState<boolean>;
  isDeclinedReasonModalOpen: boolean;
  declinedReasonValidationErrors: ValidationError | null;
  viewOnly: boolean;
  isOverdimensional: boolean;
  selectedShipper: Place | undefined | null;
  selectedConsignee: Place | undefined | null;
  hasQuoteChanged: boolean;
  hasRateContactChanged: boolean;
  editMode: boolean;
  editQuoteOtherInfoMode: boolean;
  isNewWorkaround: boolean;
}

const initialState = {
  customerQuote: {},
  shadowQuote: {},
  quoteValidationErrors: null,
  isPrintQuoteViewOpen: false,
  shipmentNotes: undefined,
  quoteFetchResults: managedAjaxUtil.createInitialState(),
  quoteSaveResults: managedAjaxUtil.createInitialState(),
  quoteStatusSaveResults: managedAjaxUtil.createInitialState(),
  quoteOtherInfoSaveResults: managedAjaxUtil.createInitialState(),
  quoteContactSaveResults: managedAjaxUtil.createInitialState(),
  rateFetchResults: managedAjaxUtil.createInitialState(),
  tripAnalyzerResults: managedAjaxUtil.createInitialState(),
  isDeclinedReasonModalOpen: false,
  declinedReasonValidationErrors: null,
  viewOnly: false,
  isOverdimensional: false,
  selectedShipper: undefined,
  selectedConsignee: undefined,
  hasQuoteChanged: false,
  hasRateContactChanged: false,
  editMode: false,
  editQuoteOtherInfoMode: false,
  isNewWorkaround: false
} as IQuoteEntryState

export type CustomerType = "Shipper" | "Consignee";

class QuoteEntryFreezerService extends FreezerService<IQuoteEntryState, typeof InjectedPropName> {
  constructor() {
    super(initialState, InjectedPropName);
    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
    this.freezer.get().set(initialState);
  }

  @bind
  public setNewWorkaround(isNewWorkaround: boolean) {
    this.freezer.get().set({ isNewWorkaround: isNewWorkaround });
  }

  public setCustomerQuote(customerQuote: CustomerQuote) {
    this.freezer.get().set({
      customerQuote: customerQuote,
      viewOnly: true,
      isOverdimensional: customerQuote.rateVariableFactor === "Overdimensional",
      shadowQuote: customerQuote,
      hasQuoteChanged: false
    });

    this.formatShipperConsigneeDateTimes();
  }

  public updateCustomerQuote(customerQuote: Partial<CustomerQuote>, includeShadow: boolean = false) {
    this.freezer.get().customerQuote.set(customerQuote);

    // set if changes have been saved, so shadow should be mirrored to prevent incorrect change detection
    if (includeShadow) {
      this.freezer.get().shadowQuote?.set(customerQuote);
    } else {
      this._runQuoteChangeDetection();
    }
  }

  public setShipperPlace(place: Place | null) {
    this.freezer.get().set({ selectedShipper: place });
  }

  public setConsigneePlace(place: Place | null) {
    this.freezer.get().set({ selectedConsignee: place });
  }

  public openDeclinedReasonModal() {
    this.freezer.get().set({
      isDeclinedReasonModalOpen: true
    });
  }

  public cancelDeclinedReasonModal() {
    this.freezer.get().set({
      isDeclinedReasonModalOpen: false
    });
    this.updateCustomerQuote({ quoteStatusMessage: "" });
  }

  public async closeDeclinedReasonModal() {
    const customerQuote = this.freezer.get().customerQuote.toJS();
    const errors = await validateSchema(DeclinedReasonSchema, customerQuote.quoteStatusMessage, {
      abortEarly: false
    });

    this.freezer.get().set({ declinedReasonValidationErrors: errors });

    if (errors) {
      return;
    }

    this.freezer.get().set({
      isDeclinedReasonModalOpen: false
    });
    this.saveQuoteStatus("Declined");
  }

  public openPrintView() {
    this.freezer.get().set({
      isPrintQuoteViewOpen: true
    });
  }

  public closePrintView() {
    this.freezer.get().set({
      isPrintQuoteViewOpen: false
    });
  }

  public updateEditMode(isEditMode: boolean) {
    const {
      customerQuote
    } = this.freezer.get().toJS();

    this.freezer.get().set({
      customerQuote: customerQuote, // TODO: Investigate QuoteView.tsx customer quote freezer state inconsistent without this set.
      editMode: isEditMode,
      viewOnly: !isEditMode
    });
  }

  public updateEditQuoteOtherInfoMode(isEditMode: boolean) {
    this.freezer.get().set({
      editQuoteOtherInfoMode: isEditMode
    });
  }

  public async fetchQuoteById(quoteId?: string) {
    if (!quoteId) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quoteFetchResults",
      params: {
        quoteId: parseInt(quoteId)
      },
      onExecute: (apiOptions, params, options) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerQuotesQuoteIdGet(params);
      },
      onOk: (data: CustomerQuote) => {
        const customerQuote = data;

        if (customerQuote.shipperZipPostalCode) {
          QuoteZipCodeMileageService.updateShipperZipCode(customerQuote.shipperZipPostalCode);
        }

        if (customerQuote.consigneeZipPostalCode) {
          QuoteZipCodeMileageService.updateConsigneeZipCode(customerQuote.consigneeZipPostalCode);
        }

        // ensure freight totals are calculated when loading an existing quote
        QuoteFreightService.calculateFreightTotalResults(customerQuote.customerQuoteFreights ?? [], customerQuote.equipmentType?.overdimensionalRulesetId);

        this.freezer.get().set({
          customerQuote: customerQuote,
          shadowQuote: customerQuote,
          isOverdimensional: customerQuote.rateVariableFactor === "Overdimensional",
          viewOnly: true
        });

        this.formatShipperConsigneeDateTimes();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to fetch quote.");
      }
    })
  }

  public async updateExpeditedShippingNotes() {
    const {
      customerQuote
    } = this.freezer.get().toJS();

    if (!_.has(customerQuote, "shipperStartDate") || !_.has(customerQuote, "consigneeStartDate") || !validateQuoteFilter(customerQuote)) {
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "tripAnalyzerResults",
      params: {
        body: customerQuote
      },
      onExecute: (apiOptions, params) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerQuotesIsShipmentExpeditedPost(params);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to analyze quote trip.");
      },
      onOk: (data: boolean) => {
        this.updateCustomerQuote({ isExpedited: data });
      }
    });
  }

  public async rateAndSaveQuote() {
    const {
      customerQuote,
      editMode
    } = this.freezer.get();

    const errors = await validateSchema(CustomerQuoteSchema, customerQuote.toJS(), {
      abortEarly: false,
      context: {
        currentTime: moment(new Date()).startOf('day')
      }
    });

    this.freezer.get().set({ quoteValidationErrors: errors });

    if (errors) {
      return;
    }

    let commodityIds: number[] = [];
    if (customerQuote.customerQuoteFreights) {
      commodityIds = _.map(customerQuote.customerQuoteFreights, qsf => qsf.commodityId!);
    }

    const addressPair: RateCalculationAddressPair[] = [{
      origin: {
        zipPostalCode: customerQuote.shipperZipPostalCode
      },
      destination: {
        zipPostalCode: customerQuote.consigneeZipPostalCode
      }
    }];

    const rateCalculationParams: RateCalculationParams = {
      miles: customerQuote.mileage,
      stops: addressPair,
      feet: customerQuote.rateVariable,
      commodityIds: commodityIds,
      tarpIds: customerQuote.tarpId && customerQuote.tarpId !== -1 ? [customerQuote.tarpId] : [],
      equipmentTypeId: customerQuote.equipmentTypeId,
      rateDate: new Date(),
      calculationRateType: "Active",
      isMarketPrimary: false,
      isMilitaryBase: customerQuote.isMilitaryBase,
      isTwicCardRequired: customerQuote.isTwicCardRequired
    };

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "rateFetchResults",
      params: {
        body: rateCalculationParams
      },
      onExecute: (apiOptions, params) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerQuotesGetRatePost(params);
      },
      onOk: (data: CustomerPortalRateEngineResult) => {
        const rateEngineResults = JSON.stringify(data);
        if (!Helpers.isNullOrUndefined(rateEngineResults)) {
          this.updateCustomerQuote({ rateEngineResults: rateEngineResults });
        }

        const newQuote = this.freezer.get().customerQuote.toJS();

        //Setting this up here so we don't display the rate for a brief second
        //for overdimensional quotes
        this.freezer.get().set({
          isOverdimensional: customerQuote.rateVariableFactor === "Overdimensional",
          shadowQuote: newQuote,
          hasQuoteChanged: false,
          editQuoteOtherInfoMode: false
        });
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to calculate quote rate.");
      }
    });

    if (!editMode) {
      this.saveQuote();
    }
  }

  public async saveQuote() {
    const {
      customerQuote
    } = this.freezer.get().toJS();
    const {
      tarp,
      equipmentType,
      customer,
      company,
      createdBy,
      ...strippedCustomerQuote
    } = customerQuote;

    if (strippedCustomerQuote.tarpId === -1) {
      strippedCustomerQuote.tarpId = undefined;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quoteSaveResults",
      params: {
        body: strippedCustomerQuote
      },
      onExecute: (apiOptions, params) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerQuotesSavePost(params);
      },
      onOk: (data: CustomerQuote) => {
        if (!data.tarpId) {
          data.tarpId = -1;
        }

        this.freezer.get().set({
          customerQuote: data,
          shadowQuote: data,
          viewOnly: true,
          editMode: false,
          editQuoteOtherInfoMode: false
        });
        this.formatShipperConsigneeDateTimes();

        if (data.rateVariableFactor === "Overdimensional") {
          ErrorService.pushErrorMessage("Freight is over dimensional. A Kaiser representative will contact you as soon as possible to provide a quote.");
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to save quote.");
      }
    });
  }

  public async saveQuoteStatus(status: CustomerQuoteQuoteStatusEnum) {

    const {
      customerQuote
    } = this.freezer.get().toJS();

    const customerQuoteVM: CustomerQuoteVM = {
      quoteId: customerQuote.id,
      quoteStatus: status,
      quoteStatusMessage: customerQuote.quoteStatusMessage ?? undefined
    }

    if (Helpers.isNullOrUndefined(customerQuote.customerContactId) && status === "Requested") {
      ErrorService.pushErrorMessage("Contact person is required before the quote can be requested.");
      return;
    }

    await managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quoteStatusSaveResults",
      params: {
        body: customerQuoteVM
      },
      onExecute: (apiOptions, params) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerQuotesSaveQuoteStatusPost(params);
      },
      onOk: () => {
        customerQuote.quoteStatus = status;
        this.freezer.get().set({
          customerQuote: customerQuote,
          viewOnly: true
        });
        this.formatShipperConsigneeDateTimes();

        if (status === "Requested") {
          if (customerQuote.rateVariableFactor === "Overdimensional") {
            ErrorService.pushErrorMessage("Freight is over dimensional. A Kaiser representative will contact you as soon as possible to provide a quote.");
          } else {
            const contactNumber = customerQuote?.customer?.salesAgent?.phoneNumber && customerQuote?.customer?.salesAgent?.phoneNumber !== "" ? customerQuote?.customer?.salesAgent?.phoneNumber : "800-590-2101";
            ErrorService.pushErrorMessage(`This is an estimated rate. Your Kaiser representative will contact you to confirm pricing and\r\nfinalize this shipment within the next business day.\r\nIf this requires action sooner, please reach out to your Kaiser representative at ${contactNumber}.`);
          }
        }
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to save quote status");
      }
    });
  }

  public saveOtherQuoteInfo(otherQuoteInfo: OtherQuoteInfoVM) {
    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "quoteOtherInfoSaveResults",
      params: {
        body: otherQuoteInfo
      },
      onExecute: (apiOptions, params) => {
        const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1CustomerQuotesSaveOtherQuoteInfoPut(params);
      },
      onOk: () => {
        const customerQuote = this.freezer.get().customerQuote.toJS();
        customerQuote.poNumber = otherQuoteInfo.poNumber;
        customerQuote.notes = otherQuoteInfo.notes;
        this.updateCustomerQuote(customerQuote, true);
        this.freezer.get().set({
          editQuoteOtherInfoMode: false
        });

        this.formatShipperConsigneeDateTimes();
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage(err.body?.message ?? "Failed to save quote: Updates to PO number, Notes and/or Close date were not saved");
      }
    });
  }

  public updateSelectedContact(contactId: number | undefined) {
    const customerQuote = this.freezer.get().customerQuote;

    this.updateCustomerQuote({ customerContactId: contactId }, true);

    if (customerQuote.id) {
      managedAjaxUtil.fetchResults({
        freezer: this.freezer,
        ajaxStateProperty: "quoteContactSaveResults",
        params: {
          body: {
            quoteId: customerQuote.id,
            contactId: contactId
          }
        },
        onExecute: (apiOptions, params, options) => {
          const factory = CustomerQuoteApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return factory.saveContactForQuote(params);
        },
        onOk: (data: CustomerQuote) => {
          this.updateCustomerQuote(data, true);
          this.freezer.get().set({
            editQuoteOtherInfoMode: false
          });
        },
        onError: (error, message) => {
          ErrorService.pushErrorMessage(error.body.message ?? "Failed to save contact to quote");
        }
      });
    }
  }

  public async copyCurrentQuote() {
    const now = moment();
    const userId = SharedSecurityContext.getUserId();
    const { contactsFetchResults } = CustomerDetailService.getState();
    const quote = this.freezer.get().customerQuote.toJS();

    const userContact = contactsFetchResults.data?.find(c => c.customerUser?.userId === userId);

    const copyQuote: CustomerQuote = {
      customerQuoteFreights: quote.customerQuoteFreights,
      shipperZipPostalCode: quote.shipperZipPostalCode,
      consigneeZipPostalCode: quote.consigneeZipPostalCode,
      declaredValue: quote.declaredValue,
      description: quote.description,
      tarpId: quote.tarpId,
      copiedQuoteNumber: quote.quoteNumber,
      copiedQuoteRate: quote.rate,
      rateVariable: quote.rateVariable,
      rateVariableFactor: quote.rateVariableFactor,
      mileage: quote.mileage,
      equipmentTypeId: quote.equipmentTypeId,
      equipmentType: { overdimensionalRulesetId: quote.equipmentType?.overdimensionalRulesetId } 
    };
    
    if (!userContact || !quote.customerContactId || (quote.customerContactId && userContact.id !== quote.customerContactId)) {
      copyQuote.customerContactId = quote.customerContactId;
    }

    let notifyInactive = false;

    const activeCommodities = CommodityService.freezer.get().commodityFetchResults.data?.toJS();

    // strip various ids from quote freights
    const strippedQuoteFreight = this._stripQuoteFreight(copyQuote.customerQuoteFreights, activeCommodities);
    await QuoteFreightService.calculateFreightTotalResults(strippedQuoteFreight ?? [], copyQuote.equipmentType?.overdimensionalRulesetId);

    // stuff that could be inactive:
    // tarps
    // commodities
    copyQuote.customerQuoteFreights = copyQuote.customerQuoteFreights?.filter(qf => {
      const commodity = activeCommodities?.find(c => c.id === qf.commodityId);
      if (commodity?.isActive) {
        return true;
      }
      else {
        notifyInactive = true;
        return false;
      }
    });

      if (quote.tarp && (!quote.tarp?.isActive || quote.tarp?.tarpValues?.length === 0)) {
      notifyInactive = true;
      delete copyQuote.tarp;
      delete copyQuote.tarpId;
    }

    this.freezer.get().set({
      viewOnly: false,
      customerQuote: copyQuote,
      quoteSaveResults: managedAjaxUtil.createInitialState(),
      rateFetchResults: managedAjaxUtil.createInitialState(),
      isNewWorkaround: true
    });

    this.updateCustomerQuote({ customerQuoteFreights: strippedQuoteFreight });

    if (notifyInactive) {
      ErrorService.pushErrorMessage("A tarp or commodity on the original quote was inactive and has been removed from the copied quote.");
    }
  }

  private _stripQuoteFreight(customerQuoteFreight: CustomerQuoteFreight[] | undefined, activeCommodities: Commodity[] | undefined) {
    if (!customerQuoteFreight) {
      return;
    }

    if (activeCommodities && customerQuoteFreight.find(f => !f.commodity)){
      _.forEach(customerQuoteFreight, f => {
        f.commodity = activeCommodities.find(c => c.id === f.commodityId) ?? undefined;
      });
    }

    const currentQuoteFreight = customerQuoteFreight.filter(f => f.commodity?.isActive);

    let strippedQuoteFreight: CustomerQuoteFreight[] = currentQuoteFreight?.map(qf => {
      const commodity = _.find(activeCommodities, c => c.id === qf.commodityId);

      return {
        commodityId: qf.commodityId,
        weight: qf.weight,
        width: qf.width,
        length: qf.length,
        height: qf.height,
        isStackable: qf.isStackable,
        isSideBySide: qf.isSideBySide,
        numberOfPieces: qf.numberOfPieces,
        commodity: {
          commodityName: commodity?.commodityName,
          isActive: commodity?.isActive,
          tmCommodityId: commodity?.tmCommodityId
        }
      };
    });

    return strippedQuoteFreight;
  }

  private _runQuoteChangeDetection() {
    const currentQuote = this.freezer.get().customerQuote.toJS();
    const shadowQuote = this.freezer.get().shadowQuote.toJS();

    if (!_.isEmpty(shadowQuote)) {
      // fields that should not trigger a re-rate
      currentQuote.poNumber = undefined;
      currentQuote.notes = undefined;
      currentQuote.description = undefined;
      currentQuote.declaredValue = undefined;
      currentQuote.shipperStartDate = undefined;
      currentQuote.shipperEndDate = undefined;
      currentQuote.consigneeStartDate = undefined;
      currentQuote.consigneeEndDate = undefined;
      currentQuote.isShipperAppointmentRequired = undefined;
      currentQuote.shipperHardTime = undefined;
      currentQuote.isConsigneeAppointmentRequired = undefined;
      currentQuote.consigneeHardTime = undefined;
      currentQuote.customerContactId = undefined;
      currentQuote.createdBy = undefined;
      currentQuote.customer = undefined;
      currentQuote.customerContact = undefined;
      currentQuote.equipmentType = undefined;
      currentQuote.tarp = undefined;
      currentQuote.company = undefined;
      currentQuote.customerQuoteFreights?.forEach(f => { f.commodity = undefined; });
      shadowQuote.poNumber = undefined;
      shadowQuote.notes = undefined;
      shadowQuote.description = undefined;
      shadowQuote.declaredValue = undefined;
      shadowQuote.shipperStartDate = undefined;
      shadowQuote.shipperEndDate = undefined;
      shadowQuote.consigneeStartDate = undefined;
      shadowQuote.consigneeEndDate = undefined;
      shadowQuote.isShipperAppointmentRequired = undefined;
      shadowQuote.shipperHardTime = undefined;
      shadowQuote.isConsigneeAppointmentRequired = undefined;
      shadowQuote.consigneeHardTime = undefined;
      shadowQuote.customerContactId = undefined;
      shadowQuote.createdBy = undefined;
      shadowQuote.customer = undefined;
      shadowQuote.customerContact = undefined;
      shadowQuote.equipmentType = undefined;
      shadowQuote.tarp = undefined;
      shadowQuote.company = undefined;
      shadowQuote.customerQuoteFreights?.forEach(f => { f.commodity = undefined; });

      if (!_.isEqual(currentQuote, shadowQuote)) {
        this.freezer.get().set({
          hasQuoteChanged: true
        });
      }
    }
  }

  private formatShipperConsigneeDateTimes() {

    const {
      customerQuote
    } = this.freezer.get().toJS();

    //trim the seconds off the returned time
    //also convert dates to format JS likes
    this.updateCustomerQuote({
      shipperHardTime: customerQuote.shipperHardTime?.substring(0, 5),
      consigneeHardTime: customerQuote.consigneeHardTime?.substring(0, 5),
      shipperStartDate: jsDateConverter(customerQuote.shipperStartDate),
      shipperEndDate: jsDateConverter(customerQuote.shipperEndDate),
      consigneeStartDate: jsDateConverter(customerQuote.consigneeStartDate),
      consigneeEndDate: jsDateConverter(customerQuote.consigneeEndDate)
    });
  }
}

export const QuoteEntryService = new QuoteEntryFreezerService();
export type IQuoteEntryServiceInjectedProps = ReturnType<QuoteEntryFreezerService["getPropsForInjection"]>;

