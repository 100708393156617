import {
  React,
  bind
} from "$Imports/Imports";

import {
  DialogActions,
  DialogContentText,
  DialogContent,
  Dialog,
  Button
} from "$Imports/MaterialUIComponents";

interface IConfirmCancelModalProps {
  isOpen: boolean;
  onCancelYes: () => void;
  onCancelNo: () => void;
  message?: string;
}

interface IConfirmCancelState {
  buttonInFocus?: HTMLButtonElement;
}

export class ConfirmCancelModal extends React.PureComponent<IConfirmCancelModalProps, IConfirmCancelState> {

  private noButtonRef: HTMLButtonElement | undefined;
  private yesButtonRef: HTMLButtonElement | undefined;

  state = {
    buttonInFocus: undefined
  };

  constructor(props: IConfirmCancelModalProps) {
    super(props);
    this.noButtonRef = undefined;
    this.yesButtonRef = undefined;
  }

  @bind
  private _setNoButtonRef(el: HTMLButtonElement) {
    if (el) {
      this.noButtonRef = el;
      this.noButtonRef.focus();
      this.setState({
        buttonInFocus: el
      });
    }
  }

  @bind
  private _setYesButtonRef(el: HTMLButtonElement) {
    if (el) {
      this.yesButtonRef = el; 
    }
  }

  @bind
  private _onConfirm(): void {
    if (this.props.onCancelYes) {
      this.props.onCancelYes();
    }
  }

  @bind
  private _onCancel(): void {
    if (this.props.onCancelNo) {
      this.props.onCancelNo();
    }
  }

  @bind
  private _onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      const currentButtonInFocus = this.state.buttonInFocus;
      var buttonToFocus: HTMLButtonElement | undefined;

      if (currentButtonInFocus === this.yesButtonRef) {
        buttonToFocus = this.noButtonRef;
      } else {
        buttonToFocus = this.yesButtonRef;
      }

      buttonToFocus?.focus();

      this.setState({
        buttonInFocus: buttonToFocus
      });
    } 
  }

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onKeyDown={this._onKeyDown}
      >
        <DialogContent>
          <DialogContentText style={{textAlign: "center"}}>
            {this.props.message ? this.props.message : "You have unsaved changes. Are you sure you want to cancel?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={this._onConfirm}
            ref={this._setYesButtonRef}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            onClick={this._onCancel}
            ref={this._setNoButtonRef}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
