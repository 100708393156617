/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as querystring from "querystring";
import * as url from "url";

const isomorphicFetch = fetch;
const assign: (target: any, ...sources: any[]) => any = (Object as any).assign;

interface Dictionary<T> { [index: string]: T; }
export interface FetchAPI { (url: string, init?: any): Promise<any>; }

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

export interface FetchArgs {
    url: string;
    options: any;
}

export class BaseAPI {
    basePath: string;
    fetch: FetchAPI;

    constructor(fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) {
        this.basePath = basePath;
        this.fetch = fetch;
    }
};

export interface AccessorialCharge {
    "id"?: number;
    "tmChargeCode"?: string;
    "description"?: string;
    "isActive"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "accessorialChargeValues"?: Array<AccessorialChargeValue>;
}

export interface AccessorialChargeValue {
    "id"?: number;
    "accessorialChargeId"?: number;
    "amount"?: number;
    "createdById"?: number;
    "createdOn"?: Date;
    "notes"?: string;
    "quoteId"?: number;
    "status"?: AccessorialChargeValueStatusEnum;
    "accessorialCharge"?: AccessorialCharge;
    "quote"?: Quote;
}

export type AccessorialChargeValueStatusEnum = "Pending" | "Approved";
export interface Activity {
    "id"?: number;
    "customerId"?: number;
    "noteText"?: string;
    "isPinned"?: boolean;
    "createdById"?: number;
    "createdOn"?: Date;
    "isActive"?: boolean;
    "activityType"?: ActivityActivityTypeEnum;
    "createdBy"?: Employee;
    "customer"?: Customer;
}

export type ActivityActivityTypeEnum = "Note" | "PhoneCallCold" | "PhoneCallFirstAppt" | "PhoneCallFollowup" | "InPersonColdCall" | "InPersonFirstAppt" | "InPersonFollowup" | "EmailColdCall" | "EmailFollowup" | "MarketingCampaign" | "Proposal" | "NewAccountHandoff" | "CustomerToProspectHandoff" | "Other";
export interface Address {
    "id"?: number;
    "quoteStopId"?: number;
    "address1"?: string;
    "address2"?: string;
    "city"?: string;
    "regionId"?: number;
    "zipPostalCode"?: string;
    "customerId"?: number;
    "addressType"?: AddressAddressTypeEnum;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "isCurrent"?: boolean;
    "customer"?: Customer;
    "region"?: Region;
}

export type AddressAddressTypeEnum = "Shipper" | "Consignee";
export interface CPAboutVM {
    "productName"?: string;
    "productVersion"?: string;
    "pcMilerProductName"?: string;
    "pcMilerProductVersion"?: string;
    "pcMilerApiVersion"?: string;
}

export interface CalculateRatingVariableResult {
    "ratingVariable"?: number;
    "ratingVariableType"?: CalculateRatingVariableResultRatingVariableTypeEnum;
    "totalLength"?: number;
    "totalWeight"?: number;
    "isWeightOverdimensional"?: boolean;
    "error"?: string;
}

export type CalculateRatingVariableResultRatingVariableTypeEnum = "Error" | "Length" | "Weight" | "Overdimensional";
export interface Commodity {
    "id"?: number;
    "commodityName"?: string;
    "commodityDescription"?: string;
    "isActive"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "commodityShortName"?: string;
    "isStackable"?: boolean;
    "isSideBySide"?: boolean;
    "tmCommodityId"?: string;
    "commodityValues"?: Array<CommodityValue>;
    "rateModelTests"?: Array<RateModelTest>;
    "commodityQuestions"?: Array<CommodityQuestion>;
    "commodityExclusions"?: Array<CommodityExclusion>;
}

export interface CommodityExclusion {
    "id"?: number;
    "commodityId"?: number;
    "customerId"?: number;
    "commodity"?: Commodity;
    "customer"?: Customer;
}

export interface CommodityQuestion {
    "id"?: number;
    "questionId"?: number;
    "commodityId"?: number;
    "commodity"?: Commodity;
    "question"?: Question;
}

export interface CommodityValue {
    "id"?: number;
    "commodityId"?: number;
    "zoneId"?: number;
    "companyId"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "percentRate"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "commodity"?: Commodity;
    "company"?: Company;
    "zone"?: Zone;
}

export interface Company {
    "id"?: number;
    "companyKey"?: string;
    "companyName"?: string;
    "primaryRate"?: number;
    "secondaryRate"?: number;
    "tmcompanyId"?: number;
    "adminEmployeeId"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
}

export interface ContactType {
    "id"?: number;
    "type"?: string;
    "customerContacts"?: Array<CustomerContact>;
}

export interface Customer {
    "id"?: number;
    "customerSourceId"?: number;
    "prospectId"?: number;
    "customerName"?: string;
    "tmcustomerId"?: string;
    "contactName"?: string;
    "emailAddress"?: string;
    "address1"?: string;
    "address2"?: string;
    "city"?: string;
    "regionId"?: number;
    "phoneNumber"?: string;
    "cellNumber"?: string;
    "zipPostalCode"?: string;
    "website"?: string;
    "isCaller"?: boolean;
    "isShipper"?: boolean;
    "isConsignee"?: boolean;
    "isActive"?: boolean;
    "isProspect"?: boolean;
    "salesAgentId"?: number;
    "sourceDetails"?: string;
    "displayAlert"?: boolean;
    "alert"?: string;
    "customerSince"?: Date;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "hasCustomerPortalAccess"?: boolean;
    "customerSource"?: CustomerSource;
    "prospect"?: Prospect;
    "salesAgent"?: Employee;
    "region"?: Region;
    "quotes"?: Array<Quote>;
    "commodityExclusions"?: Array<CommodityExclusion>;
    "customerQuotes"?: Array<CustomerQuote>;
    "customerContacts"?: Array<CustomerContact>;
    "activities"?: Array<Activity>;
    "customerHours"?: Array<CustomerHour>;
    "customerLoadingInstructions"?: Array<CustomerLoadingInstruction>;
}

export interface CustomerContact {
    "id"?: number;
    "customerId"?: number;
    "customerUserId"?: number;
    "firstName"?: string;
    "lastName"?: string;
    "nameSuffixId"?: number;
    "emailAddress"?: string;
    "phoneNumber"?: string;
    "cellNumber"?: string;
    "title"?: string;
    "notes"?: string;
    "contactTypeId"?: number;
    "isPrimary"?: boolean;
    "isActive"?: boolean;
    "isHidden"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "contactType"?: ContactType;
    "customer"?: Customer;
    "customerUser"?: CustomerUser;
    "nameSuffix"?: NameSuffix;
}

export interface CustomerContactResponseBase {
    "data"?: CustomerContact;
    "success"?: boolean;
    "error"?: CustomerContactResponseBaseError;
    "totalCount"?: number;
}

export interface CustomerContactResponseBaseError {
    "message"?: string;
    "source"?: string;
    "stackTrace"?: string;
}

export interface CustomerEntityLink {
    "isProspect"?: boolean;
    "linkId"?: number;
    "linkName"?: string;
}

export interface CustomerHour {
    "id"?: number;
    "allDay"?: boolean;
    "closeTime"?: string;
    "closed"?: boolean;
    "customerId"?: number;
    "dayOfWeek"?: CustomerHourDayOfWeekEnum;
    "openTime"?: string;
    "auditDisplayValue"?: string;
    "hasHours"?: boolean;
}

export type CustomerHourDayOfWeekEnum = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";
export interface CustomerLoadingInstruction {
    "id"?: number;
    "customerId"?: number;
    "loadingInstructionId"?: number;
    "customer"?: Customer;
    "loadingInstruction"?: LoadingInstruction;
}

export interface CustomerPortalRateEngineResult {
    "exception"?: CustomerContactResponseBaseError;
    "fuelSurcharge"?: number;
    "fuelSurchargePercentage"?: number;
    "tarpRate"?: number;
    "equipmentTypeRate"?: number;
    "highBaseRate"?: number;
    "highTotalRate"?: number;
    "lineHaulRate"?: number;
    "datRate"?: number;
    "locationFee"?: number;
    "miles"?: number;
}

export interface CustomerQuote {
    "id"?: number;
    "companyId"?: number;
    "customerId"?: number;
    "quoteDate"?: Date;
    "expirationDate"?: Date;
    "shipperZipPostalCode"?: string;
    "shipperStartDate"?: Date;
    "shipperEndDate"?: Date;
    "shipperHardTime"?: string;
    "isShipperAppointmentRequired"?: boolean;
    "consigneeZipPostalCode"?: string;
    "consigneeStartDate"?: Date;
    "consigneeEndDate"?: Date;
    "consigneeHardTime"?: string;
    "isConsigneeAppointmentRequired"?: boolean;
    "declaredValue"?: number;
    "description"?: string;
    "notes"?: string;
    "tarpId"?: number;
    "equipmentTypeId"?: number;
    "rateEngineResults"?: string;
    "quoteStatus"?: CustomerQuoteQuoteStatusEnum;
    "quoteStatusMessage"?: string;
    "mileage"?: number;
    "datRate"?: number;
    "rateVariable"?: number;
    "rateVariableFactor"?: CustomerQuoteRateVariableFactorEnum;
    "rate"?: number;
    "createdOn"?: Date;
    "createdById"?: number;
    "isExpedited"?: boolean;
    "quoteNumber"?: number;
    "copiedQuoteNumber"?: number;
    "copiedQuoteRate"?: number;
    "poNumber"?: string;
    "customerContactId"?: number;
    "isReviewed"?: boolean;
    "isMilitaryBase"?: boolean;
    "isTwicCardRequired"?: boolean;
    "fullQuote"?: SimplifiedFullQuote;
    "company"?: Company;
    "createdBy"?: CustomerUser;
    "customer"?: Customer;
    "customerContact"?: CustomerContact;
    "tarp"?: Tarp;
    "equipmentType"?: EquipmentType;
    "customerQuoteFreights"?: Array<CustomerQuoteFreight>;
}

export type CustomerQuoteQuoteStatusEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export type CustomerQuoteRateVariableFactorEnum = "Error" | "Length" | "Weight" | "Overdimensional";
export interface CustomerQuoteFreight {
    "id"?: number;
    "customerQuoteId"?: number;
    "commodityId"?: number;
    "numberOfPieces"?: number;
    "weight"?: number;
    "width"?: number;
    "length"?: number;
    "height"?: number;
    "isStackable"?: boolean;
    "isSideBySide"?: boolean;
    "createdOn"?: Date;
    "commodity"?: Commodity;
    "customerQuote"?: CustomerQuote;
}

export interface CustomerQuoteVM {
    "quoteId"?: number;
    "quoteStatus"?: CustomerQuoteVMQuoteStatusEnum;
    "quoteStatusMessage"?: string;
}

export type CustomerQuoteVMQuoteStatusEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export interface CustomerQuotesSearchCriteria {
    "quoteNumber"?: string;
    "quoteOrPONumber"?: string;
    "description"?: string;
    "notes"?: string;
    "dateType"?: CustomerQuotesSearchCriteriaDateTypeEnum;
    "quoteDate"?: Date;
    "startDate"?: Date;
    "endDate"?: Date;
    "isReviewed"?: boolean;
    "companyId"?: number;
    "quoteStatuses"?: Array<CustomerQuotesSearchCriteriaQuoteStatusesEnum>;
    "salesAgentId"?: number;
    "customerId"?: number;
    "quoteListType"?: string;
    "sortColumn"?: string;
    "sortAscending"?: boolean;
    "startIndex"?: number;
    "pageSize"?: number;
}

export type CustomerQuotesSearchCriteriaDateTypeEnum = "QuoteDate" | "DeliveryDate" | "ExpirationDate" | "PickupDate";
export type CustomerQuotesSearchCriteriaQuoteStatusesEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export interface CustomerReminder {
    "id"?: number;
    "customer"?: CustomerEntityLink;
    "customerQuote"?: EntityLink;
    "text"?: string;
    "dueDate"?: Date;
    "isComplete"?: boolean;
    "isActive"?: boolean;
    "createdById"?: number;
    "createdOn"?: Date;
    "createdByName"?: string;
}

export interface CustomerReminderSearchCriteria {
    "customerId"?: number;
    "quoteId"?: number;
    "search"?: string;
    "quoteNumber"?: string;
    "startDate"?: Date;
    "endDate"?: Date;
    "createdById"?: number;
    "reminderListType"?: string;
}

export interface CustomerResponseBase {
    "data"?: Customer;
    "success"?: boolean;
    "error"?: CustomerContactResponseBaseError;
    "totalCount"?: number;
}

export interface CustomerSource {
    "id"?: number;
    "name"?: string;
    "isActive"?: boolean;
    "requiresAdditionalDetail"?: boolean;
}

export interface CustomerUser {
    "id"?: number;
    "userId"?: string;
    "type"?: CustomerUserTypeEnum;
    "firstName"?: string;
    "lastName"?: string;
    "emailAddress"?: string;
    "tmcustomerId"?: string;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "customerQuotes"?: Array<CustomerQuote>;
    "customerContact"?: CustomerContact;
}

export type CustomerUserTypeEnum = "SalesRep" | "Manager";
export interface DatRate {
    "originZipPostalCode"?: string;
    "originMarket"?: string;
    "destZipPostalCode"?: string;
    "destMarket"?: string;
    "rate"?: number;
    "miles"?: number;
    "timeFrameDays"?: number;
    "modifiedOn"?: Date;
    "isCachedZoneRate"?: boolean;
    "hasErrors"?: boolean;
    "message"?: string;
}

export interface Employee {
    "id"?: number;
    "userId"?: string;
    "firstName"?: string;
    "lastName"?: string;
    "emailAddress"?: string;
    "isAdmin"?: boolean;
    "isManager"?: boolean;
    "isSalesRep"?: boolean;
    "isViewOnly"?: boolean;
    "isCarrierManager"?: boolean;
    "isActive"?: boolean;
    "tmuserCode"?: string;
    "tmsalesAgent"?: string;
    "phoneNumber"?: string;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
}

export interface EntityLink {
    "linkId"?: number;
    "linkName"?: string;
}

export interface EquipmentType {
    "id"?: number;
    "name"?: string;
    "tmEquipmentCode"?: string;
    "isActive"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "equipmentTypeValues"?: Array<EquipmentTypeValue>;
    "quotes"?: Array<Quote>;
    "customerQuotes"?: Array<CustomerQuote>;
}

export interface EquipmentTypeValue {
    "id"?: number;
    "equipmentTypeId"?: number;
    "companyId"?: number;
    "percentRate"?: number;
    "createdById"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "company"?: Company;
    "equipmentType"?: EquipmentType;
    "createdBy"?: Employee;
}

export interface LoadingInstruction {
    "id"?: number;
    "instruction"?: string;
    "appliesTo"?: LoadingInstructionAppliesToEnum;
    "isActive"?: boolean;
}

export type LoadingInstructionAppliesToEnum = "ShippersOnly" | "ConsigneesOnly" | "BothShippersAndConsignees";
export interface LoginVM {
    "userName"?: string;
    "password"?: string;
}

export interface LogoutVM {
    "accessToken"?: string;
    "refreshToken"?: string;
}

export interface NameSuffix {
    "id"?: number;
    "suffixValue"?: string;
    "customerContacts"?: Array<CustomerContact>;
}

export interface OtherQuoteInfoVM {
    "quoteId"?: number;
    "poNumber"?: string;
    "notes"?: string;
}

export interface Place {
    "address"?: string;
    "city"?: string;
    "stateProvince"?: string;
    "zipPostalCode"?: string;
    "county"?: string;
}

export interface Prospect {
    "id"?: number;
    "currentProvider"?: string;
    "decisionMakingType"?: ProspectDecisionMakingTypeEnum;
    "estAvgMonthlyFreightBills"?: number;
    "estAvgRevenuePerFreightBill"?: number;
    "industryType"?: ProspectIndustryTypeEnum;
    "percentageToClose"?: ProspectPercentageToCloseEnum;
    "pricingType"?: ProspectPricingTypeEnum;
    "startDate"?: Date;
    "customer"?: Customer;
}

export type ProspectDecisionMakingTypeEnum = "Customer" | "DealerNetwork" | "Local" | "Other";
export type ProspectIndustryTypeEnum = "Aerospace" | "Equipment" | "GeneratorsTransformers" | "MachineTools" | "Steel" | "Other";
export type ProspectPercentageToCloseEnum = "NoContactWithDM" | "ContactedDMInterestExpressed" | "Quoted" | "VerballyAcceptedQuote" | "OrderPlaced";
export type ProspectPricingTypeEnum = "Contracted" | "SpotQuote";
export interface Question {
    "id"?: number;
    "questionText"?: string;
    "questionType"?: QuestionQuestionTypeEnum;
    "isNa"?: boolean;
    "isActive"?: boolean;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "commodityQuestions"?: Array<CommodityQuestion>;
}

export type QuestionQuestionTypeEnum = "Commodity" | "Upcharge";
export interface Quote {
    "id"?: number;
    "quoteNumber"?: number;
    "companyId"?: number;
    "customerId"?: number;
    "createdById"?: number;
    "quoteDate"?: Date;
    "deliveryDate"?: Date;
    "negotiatedRate"?: number;
    "isMarketPrimary"?: boolean;
    "notes"?: string;
    "status"?: QuoteStatusEnum;
    "reviewedById"?: number;
    "finalizedById"?: number;
    "expirationDate"?: Date;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "upchargePercentage"?: number;
    "flatUpcharge"?: number;
    "otherFlatUpchargeReason"?: boolean;
    "flatUpchargeReason"?: string;
    "miles"?: number;
    "percentUpchargeReason"?: string;
    "totalFreightLength"?: number;
    "equipmentTypeId"?: number;
    "workflowState"?: string;
    "declineReasonText"?: string;
    "carrierId"?: number;
    "customerContactId"?: number;
    "quoteType"?: QuoteQuoteTypeEnum;
    "contactName"?: string;
    "contactPhoneNumber"?: string;
    "cancellationReason"?: QuoteCancellationReasonEnum;
    "cancellationDetails"?: string;
    "customerQuote"?: EntityLink;
    "company"?: Company;
    "customer"?: Customer;
    "createdBy"?: Employee;
    "reviewedBy"?: Employee;
    "finalizedBy"?: Employee;
    "equipmentType"?: EquipmentType;
    "carrier"?: QuoteCarrier;
    "customerContact"?: CustomerContact;
    "quoteStops"?: Array<QuoteStop>;
    "quoteFreights"?: Array<QuoteFreight>;
    "quoteQuestions"?: Array<QuoteQuestion>;
    "quoteStatusHistories"?: Array<QuoteStatusHistory>;
    "calculatedRates"?: Array<QuoteCalculatedRate>;
    "approvalReasons"?: Array<QuoteApprovalReason>;
    "accessorialChargeValues"?: Array<AccessorialChargeValue>;
}

export type QuoteStatusEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export type QuoteQuoteTypeEnum = "Full" | "Quick" | "Contract";
export type QuoteCancellationReasonEnum = "Customer" | "Kaiser" | "Other";
export interface QuoteApprovalReason {
    "id"?: number;
    "quoteId"?: number;
    "approvalNeededReasonId"?: QuoteApprovalReasonApprovalNeededReasonIdEnum;
    "approvalStatus"?: QuoteApprovalReasonApprovalStatusEnum;
    "reviewedOn"?: Date;
    "createdById"?: number;
    "createdOn"?: Date;
    "reviewedById"?: number;
    "reviewedBy"?: Employee;
}

export type QuoteApprovalReasonApprovalNeededReasonIdEnum = "OverDimensional" | "LowNegotiatedRate" | "DeclaredValue" | "MileageDifferenceOverThreshold" | "NegotiatedRateOutOfRange" | "ShipperZoneChanged" | "ConsigneeZoneChanged";
export type QuoteApprovalReasonApprovalStatusEnum = "PendingApproval" | "Approved" | "Denied";
export interface QuoteCalculateRatingVariableVM {
    "quoteFreight"?: Array<QuoteFreight>;
    "overriddenRatingVariable"?: number;
    "overriddenRatingLength"?: number;
    "equipmentTypeCode"?: string;
    "overrideLengthTimeout"?: number;
}

export interface QuoteCalculatedRate {
    "id"?: number;
    "quoteId"?: number;
    "isCurrent"?: boolean;
    "miles"?: number;
    "totalFreightLength"?: number;
    "totalFreightWeight"?: number;
    "rateLevelFactor"?: QuoteCalculatedRateRateLevelFactorEnum;
    "calculatedRateVariable"?: number;
    "overriddenRateVariable"?: number;
    "rateEngineResults"?: string;
    "highRate"?: number;
    "lowRate"?: number;
    "datRate"?: number;
    "datRateMessage"?: string;
    "timeFrameDays"?: number;
    "originMarket"?: string;
    "destMarket"?: string;
    "createdOn"?: Date;
}

export type QuoteCalculatedRateRateLevelFactorEnum = "Error" | "Length" | "Weight" | "Overdimensional";
export interface QuoteCarrier {
    "id"?: number;
    "carrierStatus"?: QuoteCarrierCarrierStatusEnum;
    "vendorId"?: number;
    "carrierRate"?: number;
    "tripNumber"?: number;
    "carrierRepId"?: number;
    "carrierRep"?: Employee;
    "vendor"?: Vendor;
    "quote"?: Quote;
}

export type QuoteCarrierCarrierStatusEnum = "Available" | "Assigned" | "OnHold" | "Picked" | "Delivered";
export interface QuoteContactVM {
    "quoteId"?: number;
    "contactId"?: number;
}

export interface QuoteFreight {
    "id"?: number;
    "quoteStopId"?: number;
    "commodityId"?: number;
    "weight"?: number;
    "width"?: number;
    "length"?: number;
    "height"?: number;
    "description"?: string;
    "isStackable"?: boolean;
    "isSideBySide"?: boolean;
    "isGrouped"?: boolean;
    "rotated"?: boolean;
    "numberOfPieces"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "quoteId"?: number;
    "serialRefNumber"?: string;
    "commodity"?: Commodity;
    "quoteStop"?: QuoteStop;
    "quote"?: Quote;
    "quoteStopFreightQuestions"?: Array<QuoteStopFreightQuestion>;
}

export interface QuoteQuestion {
    "id"?: number;
    "quoteId"?: number;
    "questionId"?: number;
    "answer"?: QuoteQuestionAnswerEnum;
    "question"?: Question;
    "quote"?: Quote;
}

export type QuoteQuestionAnswerEnum = "Yes" | "No" | "NA";
export interface QuoteStatusHistory {
    "id"?: number;
    "quoteId"?: number;
    "employeeId"?: number;
    "quoteStatus"?: QuoteStatusHistoryQuoteStatusEnum;
    "createdOn"?: Date;
    "employee"?: Employee;
    "quote"?: Quote;
}

export type QuoteStatusHistoryQuoteStatusEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export interface QuoteStop {
    "id"?: number;
    "quoteId"?: number;
    "shipperStartDate"?: Date;
    "shipperEndDate"?: Date;
    "isShipperAppointmentRequired"?: boolean;
    "consigneeStartDate"?: Date;
    "consigneeEndDate"?: Date;
    "isConsigneeAppointmentRequired"?: boolean;
    "description"?: string;
    "tarpId"?: number;
    "declaredValue"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "shipperHardTime"?: string;
    "consigneeHardTime"?: string;
    "shipmentNotes"?: string;
    "freightBillNumber"?: string;
    "freightBillCreatedOn"?: Date;
    "stopNumber"?: number;
    "isShipperExpedited"?: boolean;
    "isShipperLayover"?: boolean;
    "isConsigneeExpedited"?: boolean;
    "isConsigneeLayover"?: boolean;
    "isMilitaryBase"?: boolean;
    "isTwicCardRequired"?: boolean;
    "shipperContactId"?: number;
    "consigneeContactId"?: number;
    "ponumber"?: string;
    "siteId"?: string;
    "opsCode"?: string;
    "externalNotes"?: string;
    "quote"?: Quote;
    "tarp"?: Tarp;
    "shipperContact"?: CustomerContact;
    "consigneeContact"?: CustomerContact;
    "addresses"?: Array<Address>;
    "quoteFreights"?: Array<QuoteFreight>;
}

export interface QuoteStopFreightQuestion {
    "id"?: number;
    "quoteStopFreightId"?: number;
    "questionId"?: number;
    "answer"?: QuoteStopFreightQuestionAnswerEnum;
    "question"?: Question;
    "quoteFreight"?: QuoteFreight;
}

export type QuoteStopFreightQuestionAnswerEnum = "Yes" | "No" | "NA";
export interface RateCalculationAddressPair {
    "origin"?: Address;
    "destination"?: Address;
}

export interface RateCalculationParams {
    "datRateValue"?: number;
    "datRate"?: DatRate;
    "miles"?: number;
    "feet"?: number;
    "stops"?: Array<RateCalculationAddressPair>;
    "companyId"?: number;
    "commodityIds"?: Array<number>;
    "tarpIds"?: Array<number>;
    "upchargeFlatRate"?: number;
    "upchargePercentage"?: number;
    "isMarketPrimary"?: boolean;
    "equipmentTypeId"?: number;
    "isMilitaryBase"?: boolean;
    "isTwicCardRequired"?: boolean;
    "rateDate"?: Date;
    "calculationRateType"?: RateCalculationParamsCalculationRateTypeEnum;
}

export type RateCalculationParamsCalculationRateTypeEnum = "Default" | "Active" | "Sandbox";
export interface RateModelTest {
    "id"?: number;
    "modelLevelId"?: number;
    "originZipPostalCode"?: string;
    "destZipPostalCode"?: string;
    "datRate"?: number;
    "datRateObject"?: DatRate;
    "commodityId"?: number;
    "companyId"?: number;
    "miles"?: number;
    "defaultHighLineHaulRate"?: number;
    "defaultLowLineHaulRate"?: number;
    "activeHighLineHaulRate"?: number;
    "activeLowLineHaulRate"?: number;
    "futureHighLineHaulRate"?: number;
    "futureLowLineHaulRate"?: number;
    "sandboxHighLineHaulRate"?: number;
    "sandboxLowLineHaulRate"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "minimumAmount"?: number;
    "commodity"?: Commodity;
    "defaultCalculationInfo"?: RateModelTestCalculationInfo;
    "activeCalculationInfo"?: RateModelTestCalculationInfo;
    "futureCalculationInfo"?: RateModelTestCalculationInfo;
    "sandboxCalculationInfo"?: RateModelTestCalculationInfo;
}

export interface RateModelTestCalculationInfo {
    "tssPercent"?: number;
    "ratePerMile"?: number;
    "pricePerFoot"?: number;
    "commodityRate"?: number;
    "capacityRates"?: Array<number>;
}

export interface Region {
    "id"?: number;
    "regionName"?: string;
    "regionAbbreviation"?: string;
    "countryId"?: number;
    "regionZipPostCodes"?: Array<RegionZipPostCode>;
}

export interface RegionZipPostCode {
    "id"?: number;
    "regionId"?: number;
    "zipPostalCode"?: string;
    "zipCodeStart"?: number;
    "zipCodeEnd"?: number;
    "postalCodePrefix"?: string;
}

export interface SimplifiedFullQuote {
    "id"?: number;
    "negotiatedRate"?: number;
    "freightBillNumber"?: string;
}

export interface SimplifiedPortalCustomerQuote {
    "quoteNumber"?: string;
    "quoteDate"?: Date;
    "poNumber"?: string;
    "shipperZipCode"?: string;
    "consigneeZipCode"?: string;
    "rate"?: number;
    "miles"?: number;
    "pickupDate"?: Date;
    "deliveryDate"?: Date;
    "expirationDate"?: Date;
    "quoteStatus"?: SimplifiedPortalCustomerQuoteQuoteStatusEnum;
    "createdByCustomerName"?: string;
    "description"?: string;
    "customerContactId"?: number;
    "fullQuote"?: SimplifiedFullQuote;
}

export type SimplifiedPortalCustomerQuoteQuoteStatusEnum = "Accepted" | "Pending" | "ApprovalNeeded" | "Declined" | "InProgress" | "Expired" | "AcceptanceRejected" | "PendingNeedsCustomers" | "Approved" | "AdminRerate" | "PendingResave" | "Convert" | "Converted" | "Canceled" | "Requested" | "Completed";
export interface SimplifiedPortalCustomerQuoteSearchResult {
    "results"?: Array<SimplifiedPortalCustomerQuote>;
    "totalRecords"?: number;
    "numberOfRecords"?: number;
}

export interface Tarp {
    "id"?: number;
    "tarpName"?: string;
    "tarpDescription"?: string;
    "isActive"?: boolean;
    "companyId"?: number;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "company"?: Company;
    "tarpValues"?: Array<TarpValue>;
}

export interface TarpValue {
    "id"?: number;
    "tarpId"?: number;
    "companyId"?: number;
    "rate"?: number;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "company"?: Company;
    "tarp"?: Tarp;
}

export interface TokenInfoVM {
    "accessToken"?: string;
    "refreshToken"?: string;
}

export interface Vendor {
    "id"?: number;
    "address1"?: string;
    "address2"?: string;
    "city"?: string;
    "contactName"?: string;
    "createdOn"?: Date;
    "isActive"?: boolean;
    "modifiedOn"?: Date;
    "phoneNumber"?: string;
    "phoneExtension"?: string;
    "emailAddress"?: string;
    "regionId"?: number;
    "tmvendorId"?: string;
    "vendorName"?: string;
    "vendorSince"?: Date;
    "zipPostalCode"?: string;
    "region"?: Region;
}

export interface ZipCodePair {
    "originZipPostalCode"?: string;
    "destZipPostalCode"?: string;
}

export interface ZipCodePairMileage {
    "zipCodePair"?: ZipCodePair;
    "miles"?: number;
    "successful"?: boolean;
    "error"?: Array<string>;
}

export interface ZipCodeValidationResult {
    "zipCode"?: string;
    "isValid"?: boolean;
    "error"?: string;
}

export interface Zone {
    "id"?: number;
    "zoneName"?: string;
    "primaryZipPostalCode"?: string;
    "secondaryZipPostalCode"?: string;
    "zoneAbbreviation"?: string;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "zoneRegions"?: Array<ZoneRegion>;
}

export interface ZoneRegion {
    "id"?: number;
    "zoneId"?: number;
    "regionId"?: number;
    "zipPostalCode"?: string;
    "createdOn"?: Date;
    "modifiedOn"?: Date;
    "region"?: Region;
}



/**
 * AboutApi - fetch parameter creator
 */
export const AboutApiFetchParamCreator = {
    /** 
     * Gets the about information for the application.
     */
    apiV1AboutGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/about`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AboutApi - functional programming interface
 */
export const AboutApiFp = {
    /** 
     * Gets the about information for the application.
     */
    apiV1AboutGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CPAboutVM> {
        const fetchArgs = AboutApiFetchParamCreator.apiV1AboutGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AboutApi - object-oriented interface
 */
export class AboutApi extends BaseAPI {
    /** 
     * Gets the about information for the application.
     */
    apiV1AboutGet(options?: any) {
        return AboutApiFp.apiV1AboutGet(options)(this.fetch, this.basePath);
    }
};

/**
 * AboutApi - factory interface
 */
export const AboutApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets the about information for the application.
         */
        apiV1AboutGet(options?: any) {
            return AboutApiFp.apiV1AboutGet(options)(fetch, basePath);
        },
    };
};


/**
 * CommodityApi - fetch parameter creator
 */
export const CommodityApiFetchParamCreator = {
    /** 
     * Get a list of all commodities, not including CommodityValues
     */
    apiV1CommodityItemsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/commodity/items`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CommodityApi - functional programming interface
 */
export const CommodityApiFp = {
    /** 
     * Get a list of all commodities, not including CommodityValues
     */
    apiV1CommodityItemsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Commodity>> {
        const fetchArgs = CommodityApiFetchParamCreator.apiV1CommodityItemsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CommodityApi - object-oriented interface
 */
export class CommodityApi extends BaseAPI {
    /** 
     * Get a list of all commodities, not including CommodityValues
     */
    apiV1CommodityItemsGet(options?: any) {
        return CommodityApiFp.apiV1CommodityItemsGet(options)(this.fetch, this.basePath);
    }
};

/**
 * CommodityApi - factory interface
 */
export const CommodityApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get a list of all commodities, not including CommodityValues
         */
        apiV1CommodityItemsGet(options?: any) {
            return CommodityApiFp.apiV1CommodityItemsGet(options)(fetch, basePath);
        },
    };
};


/**
 * CustomerApi - fetch parameter creator
 */
export const CustomerApiFetchParamCreator = {
    /** 
     * 
     * @param body 
     */
    addContact(params: {  "body"?: CustomerContact; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/addContact`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     */
    apiV1CustomersCustomerUsersGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/customerUsers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     */
    apiV1CustomersTmCustomerIdGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/tmCustomerId`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get commodityExclusions for the specified customer
     * @param customerId 
     */
    getCommodityExclusionForCustomer(params: {  "customerId": number; }, options?: any): FetchArgs {
        // verify required parameter "customerId" is set
        if (params["customerId"] == null) {
            throw new Error("Missing required parameter customerId when calling getCommodityExclusionForCustomer");
        }
        const baseUrl = `/api/v1/customers/getCommodityExclusion/{customerId}`
            .replace(`{${"customerId"}}`, `${ params["customerId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     */
    getContactTypes(options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/contactTypes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param customerId 
     * @param activeOnly 
     */
    getContactsForCustomer(params: {  "customerId": number; "activeOnly": boolean; }, options?: any): FetchArgs {
        // verify required parameter "customerId" is set
        if (params["customerId"] == null) {
            throw new Error("Missing required parameter customerId when calling getContactsForCustomer");
        }
        // verify required parameter "activeOnly" is set
        if (params["activeOnly"] == null) {
            throw new Error("Missing required parameter activeOnly when calling getContactsForCustomer");
        }
        const baseUrl = `/api/v1/customers/getContacts/{customerId}/{activeOnly}`
            .replace(`{${"customerId"}}`, `${ params["customerId"] }`)
            .replace(`{${"activeOnly"}}`, `${ params["activeOnly"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param customerId 
     */
    getCustomerById(params: {  "customerId": number; }, options?: any): FetchArgs {
        // verify required parameter "customerId" is set
        if (params["customerId"] == null) {
            throw new Error("Missing required parameter customerId when calling getCustomerById");
        }
        const baseUrl = `/api/v1/customers/{customerId}`
            .replace(`{${"customerId"}}`, `${ params["customerId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    updateContact(params: {  "body"?: CustomerContact; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers/updateContact`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save updates to an existing customer in our database only
     * @param body 
     */
    updateCustomer(params: {  "body"?: Customer; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customers`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CustomerApi - functional programming interface
 */
export const CustomerApiFp = {
    /** 
     * 
     * @param body 
     */
    addContact(params: { "body"?: CustomerContact;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerContactResponseBase> {
        const fetchArgs = CustomerApiFetchParamCreator.addContact(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     */
    apiV1CustomersCustomerUsersGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CustomerUser>> {
        const fetchArgs = CustomerApiFetchParamCreator.apiV1CustomersCustomerUsersGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     */
    apiV1CustomersTmCustomerIdGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Customer> {
        const fetchArgs = CustomerApiFetchParamCreator.apiV1CustomersTmCustomerIdGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get commodityExclusions for the specified customer
     * @param customerId 
     */
    getCommodityExclusionForCustomer(params: { "customerId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CommodityExclusion>> {
        const fetchArgs = CustomerApiFetchParamCreator.getCommodityExclusionForCustomer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     */
    getContactTypes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ContactType>> {
        const fetchArgs = CustomerApiFetchParamCreator.getContactTypes(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param customerId 
     * @param activeOnly 
     */
    getContactsForCustomer(params: { "customerId": number; "activeOnly": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CustomerContact>> {
        const fetchArgs = CustomerApiFetchParamCreator.getContactsForCustomer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param customerId 
     */
    getCustomerById(params: { "customerId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Customer> {
        const fetchArgs = CustomerApiFetchParamCreator.getCustomerById(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    updateContact(params: { "body"?: CustomerContact;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerContactResponseBase> {
        const fetchArgs = CustomerApiFetchParamCreator.updateContact(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save updates to an existing customer in our database only
     * @param body 
     */
    updateCustomer(params: { "body"?: Customer;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerResponseBase> {
        const fetchArgs = CustomerApiFetchParamCreator.updateCustomer(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CustomerApi - object-oriented interface
 */
export class CustomerApi extends BaseAPI {
    /** 
     * 
     * @param body 
     */
    addContact(params: {  "body"?: CustomerContact; }, options?: any) {
        return CustomerApiFp.addContact(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     */
    apiV1CustomersCustomerUsersGet(options?: any) {
        return CustomerApiFp.apiV1CustomersCustomerUsersGet(options)(this.fetch, this.basePath);
    }
    /** 
     * 
     */
    apiV1CustomersTmCustomerIdGet(options?: any) {
        return CustomerApiFp.apiV1CustomersTmCustomerIdGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get commodityExclusions for the specified customer
     * @param customerId 
     */
    getCommodityExclusionForCustomer(params: {  "customerId": number; }, options?: any) {
        return CustomerApiFp.getCommodityExclusionForCustomer(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     */
    getContactTypes(options?: any) {
        return CustomerApiFp.getContactTypes(options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param customerId 
     * @param activeOnly 
     */
    getContactsForCustomer(params: {  "customerId": number; "activeOnly": boolean; }, options?: any) {
        return CustomerApiFp.getContactsForCustomer(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param customerId 
     */
    getCustomerById(params: {  "customerId": number; }, options?: any) {
        return CustomerApiFp.getCustomerById(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    updateContact(params: {  "body"?: CustomerContact; }, options?: any) {
        return CustomerApiFp.updateContact(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save updates to an existing customer in our database only
     * @param body 
     */
    updateCustomer(params: {  "body"?: Customer; }, options?: any) {
        return CustomerApiFp.updateCustomer(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CustomerApi - factory interface
 */
export const CustomerApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         * @param body 
         */
        addContact(params: {  "body"?: CustomerContact; }, options?: any) {
            return CustomerApiFp.addContact(params, options)(fetch, basePath);
        },
        /** 
         * 
         */
        apiV1CustomersCustomerUsersGet(options?: any) {
            return CustomerApiFp.apiV1CustomersCustomerUsersGet(options)(fetch, basePath);
        },
        /** 
         * 
         */
        apiV1CustomersTmCustomerIdGet(options?: any) {
            return CustomerApiFp.apiV1CustomersTmCustomerIdGet(options)(fetch, basePath);
        },
        /** 
         * Get commodityExclusions for the specified customer
         * @param customerId 
         */
        getCommodityExclusionForCustomer(params: {  "customerId": number; }, options?: any) {
            return CustomerApiFp.getCommodityExclusionForCustomer(params, options)(fetch, basePath);
        },
        /** 
         * 
         */
        getContactTypes(options?: any) {
            return CustomerApiFp.getContactTypes(options)(fetch, basePath);
        },
        /** 
         * 
         * @param customerId 
         * @param activeOnly 
         */
        getContactsForCustomer(params: {  "customerId": number; "activeOnly": boolean; }, options?: any) {
            return CustomerApiFp.getContactsForCustomer(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param customerId 
         */
        getCustomerById(params: {  "customerId": number; }, options?: any) {
            return CustomerApiFp.getCustomerById(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        updateContact(params: {  "body"?: CustomerContact; }, options?: any) {
            return CustomerApiFp.updateContact(params, options)(fetch, basePath);
        },
        /** 
         * Save updates to an existing customer in our database only
         * @param body 
         */
        updateCustomer(params: {  "body"?: Customer; }, options?: any) {
            return CustomerApiFp.updateCustomer(params, options)(fetch, basePath);
        },
    };
};


/**
 * CustomerQuoteApi - fetch parameter creator
 */
export const CustomerQuoteApiFetchParamCreator = {
    /** 
     * @param quoteNumber 
     */
    apiV1CustomerQuotesGet(params: {  "quoteNumber"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerQuotes`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "quoteNumber": params["quoteNumber"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets the quote rate/&gt;
     * @param body The rate calculation parameters
     */
    apiV1CustomerQuotesGetRatePost(params: {  "body"?: RateCalculationParams; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerQuotes/getRate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Applies the layover expedited flags.
     * @param body The customer quote.
     */
    apiV1CustomerQuotesIsShipmentExpeditedPost(params: {  "body"?: CustomerQuote; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerQuotes/isShipmentExpedited`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a single quote by quoteId
     * @param quoteId The id of the quote to gather
     */
    apiV1CustomerQuotesQuoteIdGet(params: {  "quoteId": number; }, options?: any): FetchArgs {
        // verify required parameter "quoteId" is set
        if (params["quoteId"] == null) {
            throw new Error("Missing required parameter quoteId when calling apiV1CustomerQuotesQuoteIdGet");
        }
        const baseUrl = `/api/v1/customerQuotes/{quoteId}`
            .replace(`{${"quoteId"}}`, `${ params["quoteId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Mark a customerQuote as completed
     * @param quoteNumber 
     */
    apiV1CustomerQuotesQuoteNumberCompletedPost(params: {  "quoteNumber": number; }, options?: any): FetchArgs {
        // verify required parameter "quoteNumber" is set
        if (params["quoteNumber"] == null) {
            throw new Error("Missing required parameter quoteNumber when calling apiV1CustomerQuotesQuoteNumberCompletedPost");
        }
        const baseUrl = `/api/v1/customerQuotes/{quoteNumber}/completed`
            .replace(`{${"quoteNumber"}}`, `${ params["quoteNumber"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Saves the OtherQuoteInfo (PO number, Notes and Close Date) of the quote
     * @param body 
     */
    apiV1CustomerQuotesSaveOtherQuoteInfoPut(params: {  "body"?: OtherQuoteInfoVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerQuotes/saveOtherQuoteInfo`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Saves the quote to the database.
     * @param body The quote to save
     */
    apiV1CustomerQuotesSavePost(params: {  "body"?: CustomerQuote; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerQuotes/save`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Saves the status of the quote
     * @param body Customer quote view model
     */
    apiV1CustomerQuotesSaveQuoteStatusPost(params: {  "body"?: CustomerQuoteVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerQuotes/saveQuoteStatus`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets list of quotes based on supplied search criteria and currently logged in user
     * @param body 
     */
    apiV1CustomerQuotesSearchPost(params: {  "body"?: CustomerQuotesSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerQuotes/search`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Calculate rating variable from given freight and override
     * @param body 
     */
    getCalculatedRatingVariable(params: {  "body"?: QuoteCalculateRatingVariableVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerQuotes/getCalculatedRatingVariable`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * 
     * @param body 
     */
    saveContactForQuote(params: {  "body"?: QuoteContactVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerQuotes/saveContactForQuote`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CustomerQuoteApi - functional programming interface
 */
export const CustomerQuoteApiFp = {
    /** 
     * @param quoteNumber 
     */
    apiV1CustomerQuotesGet(params: { "quoteNumber"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerQuote> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.apiV1CustomerQuotesGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets the quote rate/&gt;
     * @param body The rate calculation parameters
     */
    apiV1CustomerQuotesGetRatePost(params: { "body"?: RateCalculationParams;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerPortalRateEngineResult> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.apiV1CustomerQuotesGetRatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Applies the layover expedited flags.
     * @param body The customer quote.
     */
    apiV1CustomerQuotesIsShipmentExpeditedPost(params: { "body"?: CustomerQuote;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.apiV1CustomerQuotesIsShipmentExpeditedPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a single quote by quoteId
     * @param quoteId The id of the quote to gather
     */
    apiV1CustomerQuotesQuoteIdGet(params: { "quoteId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerQuote> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.apiV1CustomerQuotesQuoteIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Mark a customerQuote as completed
     * @param quoteNumber 
     */
    apiV1CustomerQuotesQuoteNumberCompletedPost(params: { "quoteNumber": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.apiV1CustomerQuotesQuoteNumberCompletedPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Saves the OtherQuoteInfo (PO number, Notes and Close Date) of the quote
     * @param body 
     */
    apiV1CustomerQuotesSaveOtherQuoteInfoPut(params: { "body"?: OtherQuoteInfoVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.apiV1CustomerQuotesSaveOtherQuoteInfoPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Saves the quote to the database.
     * @param body The quote to save
     */
    apiV1CustomerQuotesSavePost(params: { "body"?: CustomerQuote;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerQuote> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.apiV1CustomerQuotesSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Saves the status of the quote
     * @param body Customer quote view model
     */
    apiV1CustomerQuotesSaveQuoteStatusPost(params: { "body"?: CustomerQuoteVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.apiV1CustomerQuotesSaveQuoteStatusPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets list of quotes based on supplied search criteria and currently logged in user
     * @param body 
     */
    apiV1CustomerQuotesSearchPost(params: { "body"?: CustomerQuotesSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SimplifiedPortalCustomerQuoteSearchResult> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.apiV1CustomerQuotesSearchPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Calculate rating variable from given freight and override
     * @param body 
     */
    getCalculatedRatingVariable(params: { "body"?: QuoteCalculateRatingVariableVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CalculateRatingVariableResult> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.getCalculatedRatingVariable(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * 
     * @param body 
     */
    saveContactForQuote(params: { "body"?: QuoteContactVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerQuote> {
        const fetchArgs = CustomerQuoteApiFetchParamCreator.saveContactForQuote(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CustomerQuoteApi - object-oriented interface
 */
export class CustomerQuoteApi extends BaseAPI {
    /** 
     * @param quoteNumber 
     */
    apiV1CustomerQuotesGet(params: {  "quoteNumber"?: string; }, options?: any) {
        return CustomerQuoteApiFp.apiV1CustomerQuotesGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets the quote rate/&gt;
     * @param body The rate calculation parameters
     */
    apiV1CustomerQuotesGetRatePost(params: {  "body"?: RateCalculationParams; }, options?: any) {
        return CustomerQuoteApiFp.apiV1CustomerQuotesGetRatePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Applies the layover expedited flags.
     * @param body The customer quote.
     */
    apiV1CustomerQuotesIsShipmentExpeditedPost(params: {  "body"?: CustomerQuote; }, options?: any) {
        return CustomerQuoteApiFp.apiV1CustomerQuotesIsShipmentExpeditedPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a single quote by quoteId
     * @param quoteId The id of the quote to gather
     */
    apiV1CustomerQuotesQuoteIdGet(params: {  "quoteId": number; }, options?: any) {
        return CustomerQuoteApiFp.apiV1CustomerQuotesQuoteIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Mark a customerQuote as completed
     * @param quoteNumber 
     */
    apiV1CustomerQuotesQuoteNumberCompletedPost(params: {  "quoteNumber": number; }, options?: any) {
        return CustomerQuoteApiFp.apiV1CustomerQuotesQuoteNumberCompletedPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Saves the OtherQuoteInfo (PO number, Notes and Close Date) of the quote
     * @param body 
     */
    apiV1CustomerQuotesSaveOtherQuoteInfoPut(params: {  "body"?: OtherQuoteInfoVM; }, options?: any) {
        return CustomerQuoteApiFp.apiV1CustomerQuotesSaveOtherQuoteInfoPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Saves the quote to the database.
     * @param body The quote to save
     */
    apiV1CustomerQuotesSavePost(params: {  "body"?: CustomerQuote; }, options?: any) {
        return CustomerQuoteApiFp.apiV1CustomerQuotesSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Saves the status of the quote
     * @param body Customer quote view model
     */
    apiV1CustomerQuotesSaveQuoteStatusPost(params: {  "body"?: CustomerQuoteVM; }, options?: any) {
        return CustomerQuoteApiFp.apiV1CustomerQuotesSaveQuoteStatusPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets list of quotes based on supplied search criteria and currently logged in user
     * @param body 
     */
    apiV1CustomerQuotesSearchPost(params: {  "body"?: CustomerQuotesSearchCriteria; }, options?: any) {
        return CustomerQuoteApiFp.apiV1CustomerQuotesSearchPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Calculate rating variable from given freight and override
     * @param body 
     */
    getCalculatedRatingVariable(params: {  "body"?: QuoteCalculateRatingVariableVM; }, options?: any) {
        return CustomerQuoteApiFp.getCalculatedRatingVariable(params, options)(this.fetch, this.basePath);
    }
    /** 
     * 
     * @param body 
     */
    saveContactForQuote(params: {  "body"?: QuoteContactVM; }, options?: any) {
        return CustomerQuoteApiFp.saveContactForQuote(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CustomerQuoteApi - factory interface
 */
export const CustomerQuoteApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param quoteNumber 
         */
        apiV1CustomerQuotesGet(params: {  "quoteNumber"?: string; }, options?: any) {
            return CustomerQuoteApiFp.apiV1CustomerQuotesGet(params, options)(fetch, basePath);
        },
        /** 
         * Gets the quote rate/&gt;
         * @param body The rate calculation parameters
         */
        apiV1CustomerQuotesGetRatePost(params: {  "body"?: RateCalculationParams; }, options?: any) {
            return CustomerQuoteApiFp.apiV1CustomerQuotesGetRatePost(params, options)(fetch, basePath);
        },
        /** 
         * Applies the layover expedited flags.
         * @param body The customer quote.
         */
        apiV1CustomerQuotesIsShipmentExpeditedPost(params: {  "body"?: CustomerQuote; }, options?: any) {
            return CustomerQuoteApiFp.apiV1CustomerQuotesIsShipmentExpeditedPost(params, options)(fetch, basePath);
        },
        /** 
         * Gets a single quote by quoteId
         * @param quoteId The id of the quote to gather
         */
        apiV1CustomerQuotesQuoteIdGet(params: {  "quoteId": number; }, options?: any) {
            return CustomerQuoteApiFp.apiV1CustomerQuotesQuoteIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Mark a customerQuote as completed
         * @param quoteNumber 
         */
        apiV1CustomerQuotesQuoteNumberCompletedPost(params: {  "quoteNumber": number; }, options?: any) {
            return CustomerQuoteApiFp.apiV1CustomerQuotesQuoteNumberCompletedPost(params, options)(fetch, basePath);
        },
        /** 
         * Saves the OtherQuoteInfo (PO number, Notes and Close Date) of the quote
         * @param body 
         */
        apiV1CustomerQuotesSaveOtherQuoteInfoPut(params: {  "body"?: OtherQuoteInfoVM; }, options?: any) {
            return CustomerQuoteApiFp.apiV1CustomerQuotesSaveOtherQuoteInfoPut(params, options)(fetch, basePath);
        },
        /** 
         * Saves the quote to the database.
         * @param body The quote to save
         */
        apiV1CustomerQuotesSavePost(params: {  "body"?: CustomerQuote; }, options?: any) {
            return CustomerQuoteApiFp.apiV1CustomerQuotesSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Saves the status of the quote
         * @param body Customer quote view model
         */
        apiV1CustomerQuotesSaveQuoteStatusPost(params: {  "body"?: CustomerQuoteVM; }, options?: any) {
            return CustomerQuoteApiFp.apiV1CustomerQuotesSaveQuoteStatusPost(params, options)(fetch, basePath);
        },
        /** 
         * Gets list of quotes based on supplied search criteria and currently logged in user
         * @param body 
         */
        apiV1CustomerQuotesSearchPost(params: {  "body"?: CustomerQuotesSearchCriteria; }, options?: any) {
            return CustomerQuoteApiFp.apiV1CustomerQuotesSearchPost(params, options)(fetch, basePath);
        },
        /** 
         * Calculate rating variable from given freight and override
         * @param body 
         */
        getCalculatedRatingVariable(params: {  "body"?: QuoteCalculateRatingVariableVM; }, options?: any) {
            return CustomerQuoteApiFp.getCalculatedRatingVariable(params, options)(fetch, basePath);
        },
        /** 
         * 
         * @param body 
         */
        saveContactForQuote(params: {  "body"?: QuoteContactVM; }, options?: any) {
            return CustomerQuoteApiFp.saveContactForQuote(params, options)(fetch, basePath);
        },
    };
};


/**
 * CustomerReminderApi - fetch parameter creator
 */
export const CustomerReminderApiFetchParamCreator = {
    /** 
     * Mark a customerReminder as complete
     * @param id 
     */
    apiV1CustomerReminderIdCompletePost(params: {  "id": number; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1CustomerReminderIdCompletePost");
        }
        const baseUrl = `/api/v1/customerReminder/{id}/complete`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Delete a customerReminder
     * @param id 
     */
    apiV1CustomerReminderIdDelete(params: {  "id": number; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1CustomerReminderIdDelete");
        }
        const baseUrl = `/api/v1/customerReminder/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a customerReminder by its ID
     * @param id 
     */
    apiV1CustomerReminderIdGet(params: {  "id": number; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1CustomerReminderIdGet");
        }
        const baseUrl = `/api/v1/customerReminder/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update a customerReminder
     * @param id 
     * @param body 
     */
    apiV1CustomerReminderIdPut(params: {  "id": number; "body"?: CustomerReminder; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1CustomerReminderIdPut");
        }
        const baseUrl = `/api/v1/customerReminder/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Create a customerReminder
     * @param body 
     */
    apiV1CustomerReminderPost(params: {  "body"?: CustomerReminder; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerReminder`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all customerReminder for the logged-in user matching the given criteria
     * @param body 
     */
    apiV1CustomerReminderSearchPost(params: {  "body"?: CustomerReminderSearchCriteria; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/customerReminder/search`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CustomerReminderApi - functional programming interface
 */
export const CustomerReminderApiFp = {
    /** 
     * Mark a customerReminder as complete
     * @param id 
     */
    apiV1CustomerReminderIdCompletePost(params: { "id": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CustomerReminderApiFetchParamCreator.apiV1CustomerReminderIdCompletePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Delete a customerReminder
     * @param id 
     */
    apiV1CustomerReminderIdDelete(params: { "id": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CustomerReminderApiFetchParamCreator.apiV1CustomerReminderIdDelete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a customerReminder by its ID
     * @param id 
     */
    apiV1CustomerReminderIdGet(params: { "id": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerReminder> {
        const fetchArgs = CustomerReminderApiFetchParamCreator.apiV1CustomerReminderIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update a customerReminder
     * @param id 
     * @param body 
     */
    apiV1CustomerReminderIdPut(params: { "id": number; "body"?: CustomerReminder;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerReminder> {
        const fetchArgs = CustomerReminderApiFetchParamCreator.apiV1CustomerReminderIdPut(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Create a customerReminder
     * @param body 
     */
    apiV1CustomerReminderPost(params: { "body"?: CustomerReminder;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CustomerReminder> {
        const fetchArgs = CustomerReminderApiFetchParamCreator.apiV1CustomerReminderPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all customerReminder for the logged-in user matching the given criteria
     * @param body 
     */
    apiV1CustomerReminderSearchPost(params: { "body"?: CustomerReminderSearchCriteria;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CustomerReminder>> {
        const fetchArgs = CustomerReminderApiFetchParamCreator.apiV1CustomerReminderSearchPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CustomerReminderApi - object-oriented interface
 */
export class CustomerReminderApi extends BaseAPI {
    /** 
     * Mark a customerReminder as complete
     * @param id 
     */
    apiV1CustomerReminderIdCompletePost(params: {  "id": number; }, options?: any) {
        return CustomerReminderApiFp.apiV1CustomerReminderIdCompletePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Delete a customerReminder
     * @param id 
     */
    apiV1CustomerReminderIdDelete(params: {  "id": number; }, options?: any) {
        return CustomerReminderApiFp.apiV1CustomerReminderIdDelete(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get a customerReminder by its ID
     * @param id 
     */
    apiV1CustomerReminderIdGet(params: {  "id": number; }, options?: any) {
        return CustomerReminderApiFp.apiV1CustomerReminderIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Update a customerReminder
     * @param id 
     * @param body 
     */
    apiV1CustomerReminderIdPut(params: {  "id": number; "body"?: CustomerReminder; }, options?: any) {
        return CustomerReminderApiFp.apiV1CustomerReminderIdPut(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Create a customerReminder
     * @param body 
     */
    apiV1CustomerReminderPost(params: {  "body"?: CustomerReminder; }, options?: any) {
        return CustomerReminderApiFp.apiV1CustomerReminderPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get all customerReminder for the logged-in user matching the given criteria
     * @param body 
     */
    apiV1CustomerReminderSearchPost(params: {  "body"?: CustomerReminderSearchCriteria; }, options?: any) {
        return CustomerReminderApiFp.apiV1CustomerReminderSearchPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CustomerReminderApi - factory interface
 */
export const CustomerReminderApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Mark a customerReminder as complete
         * @param id 
         */
        apiV1CustomerReminderIdCompletePost(params: {  "id": number; }, options?: any) {
            return CustomerReminderApiFp.apiV1CustomerReminderIdCompletePost(params, options)(fetch, basePath);
        },
        /** 
         * Delete a customerReminder
         * @param id 
         */
        apiV1CustomerReminderIdDelete(params: {  "id": number; }, options?: any) {
            return CustomerReminderApiFp.apiV1CustomerReminderIdDelete(params, options)(fetch, basePath);
        },
        /** 
         * Get a customerReminder by its ID
         * @param id 
         */
        apiV1CustomerReminderIdGet(params: {  "id": number; }, options?: any) {
            return CustomerReminderApiFp.apiV1CustomerReminderIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Update a customerReminder
         * @param id 
         * @param body 
         */
        apiV1CustomerReminderIdPut(params: {  "id": number; "body"?: CustomerReminder; }, options?: any) {
            return CustomerReminderApiFp.apiV1CustomerReminderIdPut(params, options)(fetch, basePath);
        },
        /** 
         * Create a customerReminder
         * @param body 
         */
        apiV1CustomerReminderPost(params: {  "body"?: CustomerReminder; }, options?: any) {
            return CustomerReminderApiFp.apiV1CustomerReminderPost(params, options)(fetch, basePath);
        },
        /** 
         * Get all customerReminder for the logged-in user matching the given criteria
         * @param body 
         */
        apiV1CustomerReminderSearchPost(params: {  "body"?: CustomerReminderSearchCriteria; }, options?: any) {
            return CustomerReminderApiFp.apiV1CustomerReminderSearchPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * EquipmentApi - fetch parameter creator
 */
export const EquipmentApiFetchParamCreator = {
    /** 
     * 
     */
    getEquipmentTypes(options?: any): FetchArgs {
        const baseUrl = `/api/v1/equipment/equipmentTypes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * EquipmentApi - functional programming interface
 */
export const EquipmentApiFp = {
    /** 
     * 
     */
    getEquipmentTypes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EquipmentType>> {
        const fetchArgs = EquipmentApiFetchParamCreator.getEquipmentTypes(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * EquipmentApi - object-oriented interface
 */
export class EquipmentApi extends BaseAPI {
    /** 
     * 
     */
    getEquipmentTypes(options?: any) {
        return EquipmentApiFp.getEquipmentTypes(options)(this.fetch, this.basePath);
    }
};

/**
 * EquipmentApi - factory interface
 */
export const EquipmentApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * 
         */
        getEquipmentTypes(options?: any) {
            return EquipmentApiFp.getEquipmentTypes(options)(fetch, basePath);
        },
    };
};


/**
 * PCMilerApi - fetch parameter creator
 */
export const PCMilerApiFetchParamCreator = {
    /** 
     * Lookup Places by City/State
     * @param searchCriteria 
     */
    apiV1PCMilerGetZipCodesByCityStatePost(params: {  "searchCriteria"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PCMiler/getZipCodesByCityState`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "searchCriteria": params["searchCriteria"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get mileage between them
     * @param origin 
     * @param destination 
     */
    apiV1PCMilerMileageOriginDestinationGet(params: {  "origin": string; "destination": string; }, options?: any): FetchArgs {
        // verify required parameter "origin" is set
        if (params["origin"] == null) {
            throw new Error("Missing required parameter origin when calling apiV1PCMilerMileageOriginDestinationGet");
        }
        // verify required parameter "destination" is set
        if (params["destination"] == null) {
            throw new Error("Missing required parameter destination when calling apiV1PCMilerMileageOriginDestinationGet");
        }
        const baseUrl = `/api/v1/PCMiler/mileage/{origin}/{destination}`
            .replace(`{${"origin"}}`, `${ params["origin"] }`)
            .replace(`{${"destination"}}`, `${ params["destination"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Validate a list of zip codes
     * @param body 
     */
    apiV1PCMilerValidateZipCodesPost(params: {  "body"?: Array<string>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/PCMiler/validateZipCodes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PCMilerApi - functional programming interface
 */
export const PCMilerApiFp = {
    /** 
     * Lookup Places by City/State
     * @param searchCriteria 
     */
    apiV1PCMilerGetZipCodesByCityStatePost(params: { "searchCriteria"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Place>> {
        const fetchArgs = PCMilerApiFetchParamCreator.apiV1PCMilerGetZipCodesByCityStatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get mileage between them
     * @param origin 
     * @param destination 
     */
    apiV1PCMilerMileageOriginDestinationGet(params: { "origin": string; "destination": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ZipCodePairMileage> {
        const fetchArgs = PCMilerApiFetchParamCreator.apiV1PCMilerMileageOriginDestinationGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Validate a list of zip codes
     * @param body 
     */
    apiV1PCMilerValidateZipCodesPost(params: { "body"?: Array<string>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ZipCodeValidationResult>> {
        const fetchArgs = PCMilerApiFetchParamCreator.apiV1PCMilerValidateZipCodesPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PCMilerApi - object-oriented interface
 */
export class PCMilerApi extends BaseAPI {
    /** 
     * Lookup Places by City/State
     * @param searchCriteria 
     */
    apiV1PCMilerGetZipCodesByCityStatePost(params: {  "searchCriteria"?: string; }, options?: any) {
        return PCMilerApiFp.apiV1PCMilerGetZipCodesByCityStatePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get mileage between them
     * @param origin 
     * @param destination 
     */
    apiV1PCMilerMileageOriginDestinationGet(params: {  "origin": string; "destination": string; }, options?: any) {
        return PCMilerApiFp.apiV1PCMilerMileageOriginDestinationGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Validate a list of zip codes
     * @param body 
     */
    apiV1PCMilerValidateZipCodesPost(params: {  "body"?: Array<string>; }, options?: any) {
        return PCMilerApiFp.apiV1PCMilerValidateZipCodesPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * PCMilerApi - factory interface
 */
export const PCMilerApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Lookup Places by City/State
         * @param searchCriteria 
         */
        apiV1PCMilerGetZipCodesByCityStatePost(params: {  "searchCriteria"?: string; }, options?: any) {
            return PCMilerApiFp.apiV1PCMilerGetZipCodesByCityStatePost(params, options)(fetch, basePath);
        },
        /** 
         * Get mileage between them
         * @param origin 
         * @param destination 
         */
        apiV1PCMilerMileageOriginDestinationGet(params: {  "origin": string; "destination": string; }, options?: any) {
            return PCMilerApiFp.apiV1PCMilerMileageOriginDestinationGet(params, options)(fetch, basePath);
        },
        /** 
         * Validate a list of zip codes
         * @param body 
         */
        apiV1PCMilerValidateZipCodesPost(params: {  "body"?: Array<string>; }, options?: any) {
            return PCMilerApiFp.apiV1PCMilerValidateZipCodesPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * RegionApi - fetch parameter creator
 */
export const RegionApiFetchParamCreator = {
    /** 
     * Gets a list of Regions
     */
    apiV1RegionGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/region`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * RegionApi - functional programming interface
 */
export const RegionApiFp = {
    /** 
     * Gets a list of Regions
     */
    apiV1RegionGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Region>> {
        const fetchArgs = RegionApiFetchParamCreator.apiV1RegionGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * RegionApi - object-oriented interface
 */
export class RegionApi extends BaseAPI {
    /** 
     * Gets a list of Regions
     */
    apiV1RegionGet(options?: any) {
        return RegionApiFp.apiV1RegionGet(options)(this.fetch, this.basePath);
    }
};

/**
 * RegionApi - factory interface
 */
export const RegionApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets a list of Regions
         */
        apiV1RegionGet(options?: any) {
            return RegionApiFp.apiV1RegionGet(options)(fetch, basePath);
        },
    };
};


/**
 * TarpApi - fetch parameter creator
 */
export const TarpApiFetchParamCreator = {
    /** 
     * Gets a list of tarps
     * @param companyId 
     * @param activeOnly 
     */
    apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(params: {  "companyId": number; "activeOnly": boolean; }, options?: any): FetchArgs {
        // verify required parameter "companyId" is set
        if (params["companyId"] == null) {
            throw new Error("Missing required parameter companyId when calling apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet");
        }
        // verify required parameter "activeOnly" is set
        if (params["activeOnly"] == null) {
            throw new Error("Missing required parameter activeOnly when calling apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet");
        }
        const baseUrl = `/api/v1/tarp/getAllTarps/{companyId}/{activeOnly}`
            .replace(`{${"companyId"}}`, `${ params["companyId"] }`)
            .replace(`{${"activeOnly"}}`, `${ params["activeOnly"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * TarpApi - functional programming interface
 */
export const TarpApiFp = {
    /** 
     * Gets a list of tarps
     * @param companyId 
     * @param activeOnly 
     */
    apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(params: { "companyId": number; "activeOnly": boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Tarp>> {
        const fetchArgs = TarpApiFetchParamCreator.apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * TarpApi - object-oriented interface
 */
export class TarpApi extends BaseAPI {
    /** 
     * Gets a list of tarps
     * @param companyId 
     * @param activeOnly 
     */
    apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(params: {  "companyId": number; "activeOnly": boolean; }, options?: any) {
        return TarpApiFp.apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * TarpApi - factory interface
 */
export const TarpApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets a list of tarps
         * @param companyId 
         * @param activeOnly 
         */
        apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(params: {  "companyId": number; "activeOnly": boolean; }, options?: any) {
            return TarpApiFp.apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * UsersApi - fetch parameter creator
 */
export const UsersApiFetchParamCreator = {
    /** 
     * @param body 
     */
    apiV1UserLoginPost(params: {  "body"?: LoginVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/user/login`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1UserLogoutPost(params: {  "body"?: LogoutVM; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/user/logout`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiV1UserRefreshPost(params: {  "body"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/user/refresh`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * UsersApi - functional programming interface
 */
export const UsersApiFp = {
    /** 
     * @param body 
     */
    apiV1UserLoginPost(params: { "body"?: LoginVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenInfoVM> {
        const fetchArgs = UsersApiFetchParamCreator.apiV1UserLoginPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1UserLogoutPost(params: { "body"?: LogoutVM;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = UsersApiFetchParamCreator.apiV1UserLogoutPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiV1UserRefreshPost(params: { "body"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenInfoVM> {
        const fetchArgs = UsersApiFetchParamCreator.apiV1UserRefreshPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * UsersApi - object-oriented interface
 */
export class UsersApi extends BaseAPI {
    /** 
     * @param body 
     */
    apiV1UserLoginPost(params: {  "body"?: LoginVM; }, options?: any) {
        return UsersApiFp.apiV1UserLoginPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1UserLogoutPost(params: {  "body"?: LogoutVM; }, options?: any) {
        return UsersApiFp.apiV1UserLogoutPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiV1UserRefreshPost(params: {  "body"?: string; }, options?: any) {
        return UsersApiFp.apiV1UserRefreshPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * UsersApi - factory interface
 */
export const UsersApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param body 
         */
        apiV1UserLoginPost(params: {  "body"?: LoginVM; }, options?: any) {
            return UsersApiFp.apiV1UserLoginPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1UserLogoutPost(params: {  "body"?: LogoutVM; }, options?: any) {
            return UsersApiFp.apiV1UserLogoutPost(params, options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiV1UserRefreshPost(params: {  "body"?: string; }, options?: any) {
            return UsersApiFp.apiV1UserRefreshPost(params, options)(fetch, basePath);
        },
    };
};
