import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  SimplifiedPortalCustomerQuote,
  CustomerQuotesSearchCriteria,
  SimplifiedFullQuote
} from "$Generated/api";

import {
  AcceptedCustomerQuotesSearchForm
} from "./AcceptedCustomerQuotesSearchForm";

import {
  AjaxActionIndicator,
  BinaryChoiceDialog,
  CardLinedHeader,
  DisplayFormattedDatetime,
  DisplayFormattedNumber,
  ToggleBox,
  UserAccessControl
} from "$Imports/CommonComponents";

import {
  Card,
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
  DataGridPro,
  Link,
  IconButton
} from "$Imports/MaterialUIComponents";

import {
  ICustomerQuoteServiceInjectedProps,
  CustomerQuoteService
} from "$State/CustomerQuotesFreezerService";

import { Check } from "$Imports/MaterialUIIcons";

const styles: {
  searchFormContainer: string;
  cardStyle: string;
  resultsGrid: string;
  resultsMessage: string;
  resultCell: string;
  customerQuoteRow: string;
  customerQuoteActions: string;
  hideAction: string;
} = require("./AcceptedQuotes.scss");

const quoteListTypes = ["Just Me", "Everyone"];
type quoteListType = typeof quoteListTypes[number];

interface IOwnProps {
  onViewQuote: (quoteNumber: string | undefined) => void;
}

interface IOwnState {
  selectedQuoteListType: quoteListType;
  isCompleteQuoteModalOpen: boolean;
  completeQuoteNumber?: number;
  isCompletedMessage: string;
}

type  IAcceptedQuotesProps = IOwnProps
  & ICustomerQuoteServiceInjectedProps;

export class _AcceptedQuotes extends React.Component<IAcceptedQuotesProps, IOwnState> {
  state: IOwnState = {
    selectedQuoteListType: "Just Me",
    isCompleteQuoteModalOpen: false,
    completeQuoteNumber: undefined,
    isCompletedMessage: ""
  };
  
  componentDidMount() {
    this.props.customerQuotesService.resetAcceptedQuotes();
    this.props.customerQuotesService.fetchAcceptedQuotes(true);
  }

  @bind
  private _getColumns(): GridColDef[] {
    return [{
      field: "quoteNumber",
      headerName: "Quote #",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, SimplifiedPortalCustomerQuote>) => {
        return (
          <Link
            style={{cursor: "pointer"}}
            color="secondary"
            onClick={() => this.props.onViewQuote(params.row.quoteNumber)}
          >
            {`EQ${params.value}`}
          </Link>
        );
      },
      width: 80
    }, {
      field: "createdByCustomerName",
      headerName: "Creator",
      disableColumnMenu: true,
      width: 150
    }, {
      field: "quoteDate",
      headerName: "Quote Date",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Date | undefined>) => 
        <DisplayFormattedDatetime value={params.value} formatString={'MM/DD/YYYY'} manualOffset={true} />,
      minWidth: 100,
      flex: 1
    }, {
      field: "pickupDate",
      headerName: "Pickup Date",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Date | undefined>) => 
        <DisplayFormattedDatetime value={params.value} formatString={'MM/DD/YYYY'} manualOffset={true} />,
      minWidth: 100,
      flex: 1
    }, {
      field: "deliveryDate",
      headerName: "Delivery Date",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<Date | undefined>) =>  
        params.value 
          ? <DisplayFormattedDatetime value={params.value} formatString={'MM/DD/YYYY'} manualOffset={true} />
          : "",
      minWidth: 105,
      flex: 1
    }, {
      field: "negotiatedRate",
      headerName: "Rate",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<SimplifiedFullQuote | undefined>) => 
        params.row.fullQuote ? <DisplayFormattedNumber value={params.row.fullQuote?.negotiatedRate} formatString={"$0,0.00"} /> : "-",
      width: 100
    }, {
      field: "shipperZip",
      headerName: "Shipper",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, SimplifiedPortalCustomerQuote>) => 
      params.row.shipperZipCode ?? "",
      flex: 1
    }, {
      field: "consigneeZip",
      headerName: "Consignee",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<any, SimplifiedPortalCustomerQuote>) =>
        params.row.consigneeZipCode ?? "",
      flex: 1
    }, {
      field: "miles",
      headerName: "Miles",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<number | undefined>) => 
        params.value ? <DisplayFormattedNumber value={params.value} formatString={"0"} /> : "",
      flex: 1
    }, {
      field: "poNumber",
      headerName: "PO #",
      disableColumnMenu: true,
      flex: 1
    }, {
      field: "freightBillNumber",
      headerName: "Kaiser Ref #",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<string | undefined>) => 
        params.row.fullQuote?.freightBillNumber ?? "",
      width: 100
    }, {
      field: "actions",
      headerName: "",
      renderCell: (params: GridRenderCellParams<number, SimplifiedPortalCustomerQuote>) => (
        <div className={styles.customerQuoteActions}>
          <IconButton
            className={styles.hideAction}
            onClick={() => this._onOpenCompleteQuoteModal(params.row.quoteNumber)}
            size="small"
            title="Complete Quote"
          >
            <Check />
          </IconButton>
        </div>
      ),
      width: 50,
      disableColumnMenu: true,
      sortable: false
    }];
  }

  @bind
  private _onSortChange(sortModel: GridSortModel) {
    if (sortModel.length === 0) {
      const { acceptedSearchCriteria } = this.props.customerQuotesService.getState();
      sortModel = [{
        field: acceptedSearchCriteria.sortColumn ?? "deliveryDate",
        sort: acceptedSearchCriteria.sortAscending ? "asc" : "desc"
      }];
    }
    this.props.customerQuotesService.onSearchModelChanged({
      sortColumn: sortModel[0].field,
      sortAscending: sortModel[0].sort === "asc"
    }, true);

    this.props.customerQuotesService.fetchAcceptedQuotes(true);
  }

  @bind
  private selectQuoteListType(quoteListType: string) {
    if (quoteListType === this.state.selectedQuoteListType) {
      return;
    }

    this.setState({
      selectedQuoteListType: quoteListType as quoteListType
    });

    CustomerQuoteService.freezer.get().acceptedSearchCriteria.set({
      quoteListType: quoteListType
    });

    this.props.customerQuotesService.fetchAcceptedQuotes(true);
  }

  @bind
  private _onOpenCompleteQuoteModal(quoteNumber: string | undefined) {
    this.setState({
      isCompleteQuoteModalOpen: true,
      completeQuoteNumber: Number(quoteNumber) ?? undefined,
      isCompletedMessage: `Are you sure you want to change the status of quote EQ${quoteNumber} to Completed?`
    })
  }

  @bind
  private async _onMarkCompletedConfirm() {
    const { completeQuoteNumber } = this.state;
    if (completeQuoteNumber) {
      await this.props.customerQuotesService.completeQuote(completeQuoteNumber);
      await this.props.customerQuotesService.fetchAcceptedQuotes(true);
      this._onCloseCompleteQuoteModal();
    }
  }

  @bind
  private _onCloseCompleteQuoteModal() {
    this.setState({
      isCompleteQuoteModalOpen: false
    });
  }

  @bind
  private async _onSearchSubmit(search: CustomerQuotesSearchCriteria): Promise<void> {
    search.quoteListType = this.state.selectedQuoteListType;

    CustomerQuoteService.freezer.get().set({
      acceptedSearchCriteria: {...search}
    });

    this.props.customerQuotesService.fetchAcceptedQuotes(true);
  }

  render() {

    const {
      acceptedSearchCriteria,
      acceptedQuotesFetchResults
    } = this.props.customerQuotesService.getState();
    
    const {
      selectedQuoteListType,
      isCompleteQuoteModalOpen,
      isCompletedMessage
    } = this.state;

    let quoteData = acceptedQuotesFetchResults.data?.results ?? [];

    const sortModel: GridSortModel = [{
      field: acceptedSearchCriteria.sortColumn ?? "deliveryDate",
      sort: acceptedSearchCriteria.sortAscending ? "asc" : "desc"
    }];
    
    return (
      <Card className={styles.cardStyle}>
        <CardLinedHeader 
          title="Accepted Quotes"
          color="white"
          style={{ display: "flex", flexDirection: "column", padding: "0.625rem 0.625rem 0rem 0.625rem" }}
          titleComponents={<UserAccessControl roles={["customer-portal:manager"]}>
            <ToggleBox
              selectedValue={selectedQuoteListType}
              options={quoteListTypes}
              toggleClick={this.selectQuoteListType}
            />
          </UserAccessControl>}
        />

        <AjaxActionIndicator
          state={[acceptedQuotesFetchResults]}
        />

        <div className={styles.resultsGrid}>      
          <div className={styles.searchFormContainer}>
            <AcceptedCustomerQuotesSearchForm
              onSubmit={this._onSearchSubmit}
            />
          </div>
          
          {(acceptedQuotesFetchResults.hasFetched && (acceptedQuotesFetchResults.data?.totalRecords ?? 0) > (acceptedQuotesFetchResults.data?.numberOfRecords ?? 0)) ? (
            <div className={styles.resultsMessage}>
              {acceptedQuotesFetchResults.data?.totalRecords} results found, {acceptedQuotesFetchResults.data?.numberOfRecords} shown - please refine your search.
            </div>
          ) : undefined}

          <DataGridPro
            columns={this._getColumns()}
            rows={quoteData}
            classes={{
              cell: styles.resultCell,
              row: styles.customerQuoteRow
            }}
            density="compact"
            initialState={{
              sorting: { sortModel: sortModel }
            }}
            sortingMode="server"
            onSortModelChange={this._onSortChange}
            disableSelectionOnClick
            hideFooter
            getRowId={(row: any) => row.quoteNumber}
            columnVisibilityModel={{
              createdByCustomerName: selectedQuoteListType === "Everyone"
            }}
          />

          <BinaryChoiceDialog
            maxWidth="sm"
            title="Complete Quote"
            isOpen={isCompleteQuoteModalOpen}
            message={isCompletedMessage}
            falseText="Cancel"
            trueText="Confirm"
            onClick={(value) => {
              if (value) {
                this._onMarkCompletedConfirm();
              } else {
                this._onCloseCompleteQuoteModal();
              }
            }}
          />
        </div>
      </Card>
    );
  }
}

export const AcceptedQuotes = CustomerQuoteService.inject(
  _AcceptedQuotes
);