
import {
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "$Imports/MaterialUIComponents";

import {
  AdvanceTextField
} from "$Imports/CommonComponents";

import { 
  IQuoteEntryServiceInjectedProps,
  QuoteEntryService 
} from "$State/QuoteEntryFreezerService";

interface IDeclinedReasonModalBaseProps {
}

type IDeclinedReasonModalProps = IDeclinedReasonModalBaseProps & IQuoteEntryServiceInjectedProps

class _DeclinedReasonModal extends React.Component<IDeclinedReasonModalProps> {

  @bind 
  private _onCancel() {
    this.props.QuoteEntryService.cancelDeclinedReasonModal();
  }

  @bind
  private _onReasonChange(reason: string) {
    this.props.QuoteEntryService.updateCustomerQuote({ quoteStatusMessage: reason });
  }

  @bind
  private _onSave() {
    this.props.QuoteEntryService.closeDeclinedReasonModal();
  }

  render() {

    const {
      isDeclinedReasonModalOpen,
      customerQuote,
      declinedReasonValidationErrors
    } = this.props.QuoteEntryService.getState();

    const reason = customerQuote.quoteStatusMessage ?? "";

    return (
      <Dialog
        open={isDeclinedReasonModalOpen}
        fullWidth={true}
      >
        <DialogTitle>
          Reason for Declined Quote
        </DialogTitle>
        <DialogContent>
          <AdvanceTextField
            label="Reason"
            onDebouncedChange={this._onReasonChange}
            value={reason ?? ""}
            fullWidth
            error={!!(declinedReasonValidationErrors?.errors.length)}
            helperText={declinedReasonValidationErrors?.errors}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={this._onSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={this._onCancel}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )

  }
}

export const DeclinedReasonModal = QuoteEntryService.inject(_DeclinedReasonModal);