import {
  bind,
  _,
  React,
  moment
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  KeyboardDatePicker,
  MenuItem,
  Select,
  TextField,
  TextFieldProps
} from "$Imports/MaterialUIComponents";

import {
  CustomerReminder,
  CustomerUser
} from "$Generated/api";

import {
  CustomerReminderValidationSchema,
  ICustomerReminderServiceInjectedProps,
  CustomerReminderService
} from "$State/CustomerReminderFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import {
  SharedSecurityContext
} from "$Shared/utilities/Security/ApplicationSecuritySettings";

import {
  UserAccessControl
} from "./Security/UserAccessControl";

interface IOwnState {
  reminder: CustomerReminder;
  errors: ValidationError | null;
  disableSave: boolean;
}

interface IOwnProps {
  isOpen: boolean;
  reminder: CustomerReminder;
  onClose: (value?: CustomerReminder) => void;
  customerUsers: CustomerUser[];
}

type OwnProps = IOwnProps
  & ICustomerReminderServiceInjectedProps;

const initialReminder: CustomerReminder = {
  text: "",
  isActive: true,
  isComplete: false
};

class _ReminderEditModal extends React.PureComponent<OwnProps, IOwnState> {
  state: IOwnState = {
    reminder: {
      ...initialReminder
    },
    errors: null,
    disableSave: false
  };

  @bind
  private _onDueDateChange(value: Date | null): void {
    this.setState((prev) => ({
      reminder: {
        ...prev.reminder,
        dueDate: moment(value).startOf('day').toDate()
      }
    }));
  }

  @bind
  private _onAssignedToChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState((prev) => ({
      reminder: {
        ...prev.reminder,
        createdById: Number(e.target.value)
      }
    }));
  }

  @bind
  private _onTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState((prev) => ({
      reminder: {
        ...prev.reminder,
        text: event.target.value
      }
    }));
  }

  @bind
  private async _onClose(shouldSave: boolean): Promise<void> {
    if(!shouldSave) {
      this.props.onClose();
      return;
    }

    const schema = CustomerReminderValidationSchema;
    const errors = await validateSchema(schema, this.state.reminder);
    if (errors) {
      this.setState({ errors: errors });
      return;
    }

    this.setState({
      disableSave: true
    });

    const { reminder } = this.state;

    if (this.state.reminder.id === 0){
      await CustomerReminderService.createCustomerReminder(reminder);
    } else {
      await CustomerReminderService.updateCustomerReminder(reminder.id ?? 0, reminder);
    }

    this.props.onClose(reminder);
  }

  @bind
  private _onDueDateOpen(): void {
    const now = moment().startOf('day').toDate();
    if (this.state.reminder.dueDate && new Date(this.state.reminder.dueDate).valueOf() < now.valueOf()) {
      this.setState((prev) => ({
        reminder: {
          ...prev.reminder,
          dueDate: now
        }
      }));
    }
  }

  componentDidUpdate(prev: IOwnProps) {

    if(prev.isOpen !== this.props.isOpen) {
      this.setState({
        disableSave: false
      })
    }

    if (this.props.reminder !== prev.reminder) {
      let createdById = this.props.reminder.createdById;

      if (!createdById) {
        const userId = SharedSecurityContext.getUserId();
        createdById = _.find(this.props.customerUsers, (u) => u.userId === userId)?.id;
      }

      this.setState({
        reminder: {
          ...initialReminder,
          ...this.props.reminder,
          createdById: createdById
        },
        errors: null
      });
    }
  }

  render() {
    const {
      isOpen,
      customerUsers
    } = this.props;

    const {
      reminder: {
        id,
        dueDate,
        text,
        customerQuote,
        createdById
      },
      errors,
      disableSave
    } = this.state;

    const validationParser = new ValidationErrorParser<CustomerReminder>(errors);
    const isAdd = !id;

    return (
      <Dialog
        open={isOpen}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          {isAdd ? "Set" : "Edit"} Reminder
        </DialogTitle>

        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <KeyboardDatePicker
                value={dueDate ?? null}
                onChange={this._onDueDateChange}
                onOpen={this._onDueDateOpen}
                inputFormat="MM/DD/YYYY"
                label="Due Date"
                disablePast
                renderInput={(props: TextFieldProps) => (
                  <TextField
                    {...props}
                    error={!validationParser.isValid("dueDate")}
                    helperText={validationParser.validationMessage("dueDate")}
                    required
                  />
                )}
              />
            </Grid>
            {customerQuote?.linkId && (
              <Grid item>
                <TextField
                  label="Quote #"
                  value={`EQ${customerQuote.linkName}`}
                  disabled
                />
              </Grid>
            )}
            <UserAccessControl roles={["customer-portal:manager"]}>
              <Grid item>
                <FormControl>
                  <InputLabel shrink>Assigned To</InputLabel>
                  <Select
                    value={createdById}
                    name="customerUserId"
                    onChange={(event) => this._onAssignedToChange(event as React.ChangeEvent<HTMLInputElement>)}
                  >
                    {customerUsers.map((s, idx) => (
                      <MenuItem value={s.id} key={idx}>{`${s.firstName} ${s.lastName}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </UserAccessControl>
            <Grid item>
              <TextField
                label="Description"
                value={text}
                onChange={this._onTextChange}
                maxRows={3}
                inputProps={{
                  maxLength: 300
                }}
                error={!validationParser.isValid("text")}
                helperText={validationParser.validationMessage("text")}
                multiline
                fullWidth
                required
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            disabled={disableSave}
            onClick={() => this._onClose(true)}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => this._onClose(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const ReminderEditModal = CustomerReminderService.inject(_ReminderEditModal);