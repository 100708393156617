import {
  React,
  bind
} from "$Imports/Imports";

import { 
  CustomerType
} from "$State/QuoteEntryFreezerService";

import {
  CityStateSearchResults
} from "$Imports/CommonComponents";

import {
  CityStateService,
  ICityStateFreezerServiceInjectedProps
} from "$State/CityStateFreezerService"

import {
  Place
} from "$Generated/api";

import { 
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from "$Imports/MaterialUIComponents";


interface ICityStateSearchModalBaseProps {
  isOpen?: boolean;
  customerType?: CustomerType;
  onClose?: () => void;
  onLocationSelect: (place: Place, customerType: CustomerType | undefined) => void;
}

type ICityStateSearchModalProps = ICityStateSearchModalBaseProps & ICityStateFreezerServiceInjectedProps;

class _CityStateSearchModal extends React.Component<ICityStateSearchModalProps> {

  @bind
  private _onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  @bind
  private _onLocationSelect() {
    const place = this.props.CityStateService.getState().selectedRow;
    const customerType = this.props.customerType;

    if (this.props.onLocationSelect && place) {
      this.props.onLocationSelect(place, customerType);
    }
  }

  render() {
    const {
      isOpen
    } = this.props;

    const {
      selectedRow
    } = this.props.CityStateService.getState();

    return (
      <Dialog
        open={isOpen === true}
        maxWidth="md"
      >
        <DialogTitle>
          {`City, State Search`}
        </DialogTitle>
        <DialogContent>
          <CityStateSearchResults 
            onEnterPressWithSelectedRow={this._onLocationSelect}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this._onLocationSelect}
            color="primary"
            disabled={!selectedRow}
          >
              Select
          </Button>
          <Button
            onClick={this._onClose}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export const CityStateSearchModal = CityStateService.inject(
  _CityStateSearchModal
);