import {
  React,
  bind
} from "$Imports/Imports";

import {
  CustomerReminder
} from "$Generated/api";

import { IconButton } from "$Imports/MaterialUIComponents";

import { AddCircleOutline } from "$Imports/MaterialUIIcons";

interface IOwnProps {
  onClick: (seed: CustomerReminder) => void;
  seed: CustomerReminder;
}

class _CreateReminderButton extends React.PureComponent<IOwnProps> {
  @bind
  private _onClick(): void {
    this.props.onClick(this.props.seed);
  }

  render() {
    return (
      <IconButton
        onClick={this._onClick}
        style={{ marginTop: "-0.25rem" }}
      >
        <AddCircleOutline />
      </IconButton>
    );
  }
}

export const CreateReminderButton = _CreateReminderButton;