import {
  React,
  _,
  bind
} from "$Imports/Imports";

import {
  TextField,
  TextFieldProps
} from "$Imports/MaterialUIComponents";

//#region Internal

interface IPhoneFormattingResult {
  phone: string;
  full: string;
}

function formatPhoneValue(phone: string, extension: string): IPhoneFormattingResult {
  const result: IPhoneFormattingResult = {
    phone: "",
    full: ""
  };

  const numericOnly = phone.replace(/\D/g, "");
  if (numericOnly.length === 10) {
    result.phone = `${numericOnly.substring(0, 3)}-${numericOnly.substring(3, 6)}-${numericOnly.substring(6)}`;
  }
  else {
    result.phone = phone;
  }

  result.full = !extension ? result.phone : `${result.phone} x${extension}`;

  return result;
}

function sanitizePhoneValue(phone: string, extension: string): IPhoneFormattingResult {
  const result: IPhoneFormattingResult = {
    phone: "",
    full: ""
  };

  const sanitized = phone.replace(/[^0-9\-]/g, "");

  const group1 = sanitized.substring(0, 3);
  if (!group1.match(/^[0-9]{0,3}$/)) {
    result.phone += group1.replace("-", "");
  }
  else {
    result.phone += group1;
  }

  let dashOffset = 0;

  if (sanitized.length > 3) {
    if (sanitized[3] === "-") {
      dashOffset += 1;
      result.phone += "-";
    }

    const group2 = sanitized.substring(3 + dashOffset, 6 + dashOffset);
    if (!group2.match(/^[0-9]{0,3}$/)) {
      result.phone += group2.replace("-", "");
    }
    else {
      result.phone += group2;
    }
  }

  if (sanitized.length > 6 + dashOffset) {
    if (sanitized[6 + dashOffset] === "-") {
      dashOffset += 1;
      result.phone += "-";
    }

    const group3 = sanitized.substring(6 + dashOffset, 10 + dashOffset);
    if (!group3.match(/^[0-9]{0,4}$/)) {
      result.phone += group3.replace("-", "");
    }
    else {
      result.phone += group3;
    }
  }

  result.full = !extension ? result.phone : `${result.phone} x${extension}`;

  return result;
}

//#endregion

interface IPhoneNumberInputFieldState {
  phoneNumber?: string;
  ext?: string;
  phoneNumberError: boolean;
}

interface IPhoneNumberInputFieldBaseProps {
  onPhoneNumberChange: (newValue?: string) => void;
  value?: string;
  captureExt?: boolean ;
  className?: string;
}

type IPhoneNumberInputFieldProps = IPhoneNumberInputFieldBaseProps & TextFieldProps;

export class PhoneNumberInputField extends React.Component<IPhoneNumberInputFieldProps, IPhoneNumberInputFieldState> {
  state: IPhoneNumberInputFieldState = {
    phoneNumber: undefined,
    ext: undefined,
    phoneNumberError: false
  }

  componentDidMount() {
    this._splitFullValue(this.props.value ?? "");
  }

  componentDidUpdate(prev: IPhoneNumberInputFieldProps) {
    const {
      phoneNumber,
      ext,
      phoneNumberError
    } = this.state;

    if (prev.value !== this.props.value && this.props.value !== `${phoneNumber} x${ext}`) {
      this._splitFullValue(this.props.value ?? "");
    }

    if (prev.error !== this.props.error && this.props.error !== phoneNumberError) {
      this.setState({
        phoneNumberError: !!this.props.error
      });
    }
  }

  @bind
  private _splitFullValue(full: string) {
    const parts = full.split(' x');

    this.setState({
      phoneNumber: parts?.[0] ?? "",
      ext: parts?.[1] ?? ""
    });
  }

  @bind
  private _onPhoneNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { ext } = this.state;

    const result = sanitizePhoneValue(event.target.value ?? "", ext ?? "");

    this.setState({
      phoneNumber: result.phone
    });
  }

  @bind
  private _onExtChange(event: React.ChangeEvent<HTMLInputElement>) {
    const ext = (event.target.value ?? "")
      .substring(0, 5)
      .replace(/\D/g, "");

    this.setState({
      ext: ext
    });
  }

  @bind
  private _onPhoneNumberBlur(event: React.FocusEvent<HTMLInputElement>) {
    const phoneNumber = event.target.value ?? "";
    const { ext } = this.state;

    const result = formatPhoneValue(phoneNumber, ext ?? "");
    if (result.phone !== phoneNumber) {
      this.setState({
        phoneNumber: result.phone
      });
    }

    this.props.onPhoneNumberChange(result.full);
  }

  @bind
  private _onExtBlur(event: React.FocusEvent<HTMLInputElement>) {
    const { phoneNumber } = this.state;
    const ext = event.target.value ?? "";

    const result = formatPhoneValue(phoneNumber ?? "", ext);

    this.props.onPhoneNumberChange(result.full);
  }

  render() {
    const {
      phoneNumber,
      ext,
      phoneNumberError
    } = this.state;

    const {
      value,
      onPhoneNumberChange,
      ref,
      captureExt,
      className,
      ...passthroughProps
    } = this.props;

    return (
      <div className={className ?? ""}>
        <TextField
          {...passthroughProps}
          style={{ width: "100%" }}
          onChange={this._onPhoneNumberChange}
          onBlur={this._onPhoneNumberBlur}
          value={phoneNumber ?? ""}
          error={phoneNumberError}
        />
        {captureExt &&
          <TextField
            label="Ext"
            style={{ width: "80px" }}
            onChange={this._onExtChange}
            onBlur={this._onExtBlur}
            value={ext ?? ""}
          />}
      </div>
    );
  }
}
