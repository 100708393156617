import {
  managedAjaxUtil,
  FreezerService,
  bind,
  _,
  IAjaxState
} from "$Imports/Imports";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  Tarp,
  TarpApiFactory,
  TarpValue,
} from "$Generated/api";

import {
  SitePubSubManager
} from "$Utilities/pubSubUtil";

const InjectedPropName = "TarpService";

interface ITarpFreezerState {
    tarpFetchResults: IAjaxState<Tarp[]>;
}

const initialState = {
    tarpFetchResults: managedAjaxUtil.createInitialState(),
} as ITarpFreezerState

class TarpFreezerService extends FreezerService<ITarpFreezerState, typeof InjectedPropName> {

  constructor(){
    super(initialState, InjectedPropName);
    SitePubSubManager.subscribe("application:logout", this.clearFreezer);
  }

  @bind
  public clearFreezer() {
      this.freezer.get().set(initialState);
  }

  public fetchTarps(forceUpdate: boolean = false) {
    const {
      tarpFetchResults
    } = this.freezer.get();

    if (tarpFetchResults.hasFetched && !forceUpdate) {
      return;
    }

    managedAjaxUtil.fetchResults({
      freezer: this.freezer,
      ajaxStateProperty: "tarpFetchResults",
      params: {
        activeOnly: false,
        companyId: 0
      },
      onExecute: (apiOptions, param, options) => {
        const factory = TarpApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return factory.apiV1TarpGetAllTarpsCompanyIdActiveOnlyGet(param);
      },
      onError: (err, errorMessage) => {
        ErrorService.pushErrorMessage("Failed to fetch tarps.");
      },
      onOk: (data: Tarp[]) => {
        var tarps = _.sortBy(data, t => { return t.tarpName?.toLowerCase(); });
        const noTarpValue: TarpValue = {
          id: -1,
          tarpId: -1,
          rate: 0.0000,
        };
        const noTarp: Tarp = {
          id: -1,
          tarpName: "No Tarp",
          tarpDescription: "No tarp needed for the quote's freight",
          isActive: true,
          tarpValues: [noTarpValue]
        };
        tarps.push(noTarp);
        return tarps;
      }
    });
  }
  
}

export const TarpService = new TarpFreezerService();
export type ITarpServiceInjectedProps = ReturnType<TarpFreezerService["getPropsForInjection"]>;