import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  CustomerContact,
  ContactType,
  CustomerUser
} from "$Generated/api";

import {
  PhoneNumberInputField,
  AdvanceTextField
} from "$Imports/CommonComponents";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  InputLabel,
  Switch,
  FormHelperText
} from "$Imports/MaterialUIComponents";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

const styles: {
  flexColumn: string;
  flexRow: string;
  titleRow: string;
  phoneFlexRow: string;
  input: string;
} = require("./CustomerContactModal.scss");

interface IOwnState {
  salesRepError: boolean;
}

interface ICustomerContactModalProps {
  isOpen: boolean;
  contact: CustomerContact | undefined;
  validationErrors: ValidationError | null;
  contactTypes: ContactType[];
  customerUsers: CustomerUser[];
  contacts: CustomerContact[];
  onSave: () => void;
  onCancel: () => void;
  onChange: (value: Partial<CustomerContact>) => void;
}

export class CustomerContactModal extends React.PureComponent<ICustomerContactModalProps, IOwnState> {
  state: IOwnState = {
    salesRepError: false
  }

  @bind
  private _onSave() {
    this.props.onSave();
  }

  @bind
  private _onCancel() {
    this.setState({
      salesRepError: false
    });
    this.props.onCancel();
  }

  @bind
  private _onChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange({
      [e.target.name]: e.target.value
    });
  }

  @bind
  private _onCustomerUserChange(e: React.ChangeEvent<HTMLInputElement>) {
    const salesRep = _.filter(this.props.customerUsers, (u) => u.id === parseInt(e.target.value))[0];
    const used = salesRep ? this.props.contacts.find(c => c.customerUserId === salesRep.id && salesRep.customerContact?.id !== this.props.contact?.id) !== undefined : false;
    this.setState({salesRepError: used});

    if (e.target.value === "") {
      this.props.onChange({
        [e.target.name]: undefined,
        customerUser: undefined
      })
    } else {
      this.props.onChange({
        [e.target.name]: e.target.value,
        customerUser: undefined
      });
    }
  }

  @bind
  private _onIsActiveChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    this.props.onChange({ isActive: checked });
  }

  @bind
  private _onPhoneNumberChange(phoneNumber: string | undefined) {
    this.props.onChange({ phoneNumber });
  }

  @bind
  private _onCellNumberChange(cellNumber: string | undefined) {
    if (cellNumber === "") {
      this.props.onChange({ cellNumber: undefined });
    } else {
      this.props.onChange({ cellNumber: cellNumber });
    }
  }

  render() {
    const {
      salesRepError
    } = this.state;
    const {
      isOpen,
      contact,
      validationErrors,
      contactTypes,
      customerUsers
    } = this.props;

    const validationsParser = new ValidationErrorParser<CustomerContact>(validationErrors);

    return (
      <Dialog
        open={isOpen}
        maxWidth="md"
      >
        <DialogTitle>
          <div className={styles.titleRow}>
            {contact?.id ? "Edit Contact" : "Add Contact"}
            <FormControlLabel style={{marginRight: "unset"}}
              control={
                (
                  <Switch
                    color="primary"
                    checked={!!contact?.isActive}
                    onChange={this._onIsActiveChange}
                  />
                )
              }
              label="Active"
            />
          </div>
        </DialogTitle>
        <DialogContent className={styles.flexColumn}>
          <div className={styles.flexRow}>
            <AdvanceTextField className={styles.input}
              label="First name"
              name="firstName"
              value={contact?.firstName ?? ""}
              onChange={this._onChange}
              required
              error={!validationsParser.isValid("firstName")}
              helperText={validationsParser.validationMessage("firstName")}
            />
            <AdvanceTextField className={styles.input}
              label="Last name"
              name="lastName"
              value={contact?.lastName ?? ""}
              onChange={this._onChange}
              required
              error={!validationsParser.isValid("lastName")}
              helperText={validationsParser.validationMessage("lastName")}
            />
          </div>
          <div className={styles.flexRow}>
            <AdvanceTextField className={styles.input}
              label="Title"
              name="title"
              value={contact?.title ?? ""}
              onChange={this._onChange}
              required
              error={!validationsParser.isValid("title")}
              helperText={validationsParser.validationMessage("title")}
            />
            <FormControl className={styles.input} error={!validationsParser.isValid("contactTypeId")}>
              <InputLabel required>Contact Type</InputLabel>
              <Select
                name={"contactTypeId"}
                value={contact?.contactTypeId ?? ""}
                onChange={(event) => this._onChange(event as React.ChangeEvent<HTMLInputElement>)}
              >
                {contactTypes.map((c, idx) => (
                  <MenuItem value={c.id} key={idx}>{c.type}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{validationsParser.validationMessage("contactTypeId")}</FormHelperText>
            </FormControl>
          </div>
          <div className={styles.flexRow}>
            <AdvanceTextField className={styles.input}
              label="Email"
              name="emailAddress"
              value={contact?.emailAddress ?? ""}
              onChange={this._onChange}
              required
              error={!validationsParser.isValid("emailAddress")}
              helperText={validationsParser.validationMessage("emailAddress")}
            />
            <PhoneNumberInputField
              label="Phone Number"
              name="phoneNumber"
              required
              onPhoneNumberChange={this._onPhoneNumberChange}
              captureExt
              className={`${styles.phoneFlexRow} ${styles.input}`}
              value={contact?.phoneNumber ?? ""}
              error={!validationsParser.isValid("phoneNumber")}
              helperText={validationsParser.validationMessage("phoneNumber")}
            />
          </div>
          <div className={styles.flexRow}>
            <PhoneNumberInputField
              label="Cell Number"
              name="cellNumber"
              onPhoneNumberChange={this._onCellNumberChange}
              className={`${styles.phoneFlexRow} ${styles.input}`}
              value={contact?.cellNumber ?? ""}
              error={!validationsParser.isValid("cellNumber")}
              helperText={validationsParser.validationMessage("cellNumber")}
            />
            <FormControl className={styles.input}>
              <InputLabel shrink>Assigned Sales Rep</InputLabel>
              <Select
                value={contact?.customerUserId ?? ""}
                name="customerUserId"
                onChange={(event) => this._onCustomerUserChange(event as React.ChangeEvent<HTMLInputElement>)}
                displayEmpty
                error={salesRepError}
              >
                <MenuItem value="">Not Assigned</MenuItem>
                {customerUsers.map((s, idx) => (
                  <MenuItem value={s.id} key={idx}>{`${s.firstName} ${s.lastName}`}</MenuItem>
                ))}
              </Select>
              {salesRepError && <FormHelperText>User already assigned to a contact</FormHelperText>}
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={this._onSave}
            disabled={salesRepError}
          >
            Save
          </Button>
          <Button
            onClick={this._onCancel}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
