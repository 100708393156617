import {
  _,
  React,
  bind
} from "$Imports/Imports";

import {
  ValidationError
} from "$Shared/imports/Yup";

import {
  BinaryChoiceDialog,
  AdvanceTextField,
  AjaxActionIndicator,
  PhoneNumberInputField
} from "$Imports/CommonComponents";

import {
  Customer,
  Region,
  CustomerHourDayOfWeekEnum
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "$Imports/MaterialUIComponents";

import {
  CustomerAddressRequiredValidationSchema,
  ICustomerServiceInjectedProps,
  CustomerService
} from "$State/CustomerFreezerService";

import {
  validateSchema
} from "$Shared/utilities/yupUtil";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

import { getTrimmedZipPostalCode } from "$Shared/utilities/helpers";

const styles: {
  modalContainer: string;
  inputContainer: string;
  textfield: string;
  flexRow: string;
} = require("./EditCustomerModal.scss");

interface IEditCustomerModalProps {
  isOpen: boolean;
  isFetching: boolean;
  customer: Customer;
  regions: Region[];
  onChange: (customer: Partial<Customer>) => void;
  onSave: () => void;
  onCancel: () => void;
}

type EditCustomerModalProps = IEditCustomerModalProps & ICustomerServiceInjectedProps;

interface IEditCustomerModalState {
  confirmModalOpen: boolean;
  templateDay: CustomerHourDayOfWeekEnum | "";
  validationErrors: ValidationError | null;
}

export class _editCustomerModal extends React.Component<EditCustomerModalProps, IEditCustomerModalState> {

  state: IEditCustomerModalState = {
    confirmModalOpen: false,
    templateDay: "",
    validationErrors: null
  }

  @bind
  private _onCancel() {
    this.setState({
      templateDay: "",
      validationErrors: null
    });
    this.props.onCancel();
  }

  @bind
  private async _onValidateCustomer(): Promise<boolean> {
    const schema = CustomerAddressRequiredValidationSchema;
    const customerErrors = await validateSchema(schema, this.props.customer);
    this.setState({ validationErrors: customerErrors });
    if (customerErrors) {
      return true;
    }
    return false;
  }

  @bind
  private async _onSave() {
    if(this.state.confirmModalOpen){
      this.setState({confirmModalOpen: false});
    } else {
      var hasErrors = await this._onValidateCustomer();

      if (hasErrors) {
        return;
      }
    }

    this.setState({ templateDay: "" });
    this.props.onSave();
  }

  @bind
  private _onChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.onChange({
      [e.target.name]: e.target.value
    });
  }

  @bind
  private _onZipPostalCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = getTrimmedZipPostalCode(e.target?.value);
    this.props.onChange({
      [e.target.name]: value
    });
  }

  @bind
  private _onCellNumberChange(newValue: string | undefined) {
    this.props.onChange({ cellNumber: newValue });
  }

  @bind
  private _onPhoneNumberChange(newValue: string | undefined) {
    this.props.onChange({ phoneNumber: newValue });
  }

  @bind
  private _onWebsiteBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (e.target.value && !e.target.value.startsWith("http://") && !e.target.value.startsWith("https://")) {
      this.props.onChange({ website: `https://${e.target.value}` });
    }
  }

  render() {
    const {
      isOpen,
      isFetching,
      customer,
      regions,
    } = this.props;

    const validationsParser = new ValidationErrorParser<Customer>(this.state.validationErrors);

    return (
      <>
        <Dialog
          open={isOpen}
          maxWidth="md"
        >
          <DialogTitle>Edit Customer Information</DialogTitle>
          <AjaxActionIndicator
            showProgress={isFetching}
          />
          <DialogContent className={styles.modalContainer}>
            <div className={styles.inputContainer}>
              <div>
                <AdvanceTextField
                  label="Customer Name"
                  name="customerName"
                  required
                  onChange={this._onChange}
                  value={customer.customerName ?? ""}
                  error={!validationsParser.isValid("customerName")}
                  helperText={validationsParser.validationMessage("customerName")}
                  className={styles.textfield}
                />
                <AdvanceTextField
                  label="Email"
                  name="emailAddress"
                  required={customer.isCaller}
                  onChange={this._onChange}
                  value={customer.emailAddress ?? ""}
                  error={!validationsParser.isValid("emailAddress")}
                  helperText={validationsParser.validationMessage("emailAddress")}
                  className={styles.textfield}
                />
                <AdvanceTextField
                  label="Website"
                  name="website"
                  onBlur={this._onWebsiteBlur}
                  onChange={this._onChange}
                  value={customer.website ?? ""}
                  error={!validationsParser.isValid("website")}
                  helperText={validationsParser.validationMessage("website")}
                  className={styles.textfield}
                />
                <PhoneNumberInputField
                  label="Phone Number"
                  name="phoneNumber"
                  required
                  onPhoneNumberChange={this._onPhoneNumberChange}
                  captureExt
                  value={customer.phoneNumber ?? ""}
                  error={!validationsParser.isValid("phoneNumber")}
                  helperText={validationsParser.validationMessage("phoneNumber")}
                  className={styles.flexRow}
                />
                <PhoneNumberInputField
                  label="Cell Number"
                  name="cellNumber"
                  onPhoneNumberChange={this._onCellNumberChange}
                  value={customer.cellNumber ?? ""}
                  error={!validationsParser.isValid("cellNumber")}
                  helperText={validationsParser.validationMessage("cellNumber")}
                  className={styles.textfield}
                />
              </div>
              <div>
                <AdvanceTextField
                  label="Address"
                  name="address1"
                  required
                  onChange={this._onChange}
                  value={customer.address1 ?? ""}
                  error={!validationsParser.isValid("address1")}
                  helperText={validationsParser.validationMessage("address1")}
                  className={styles.textfield}
                />
                <AdvanceTextField
                  label="Address line 2"
                  name="address2"
                  onChange={this._onChange}
                  value={customer.address2 ?? ""}
                  error={!validationsParser.isValid("address2")}
                  helperText={validationsParser.validationMessage("address2")}
                  className={styles.textfield}
                />
                <AdvanceTextField
                  label="City"
                  name="city"
                  required
                  onChange={this._onChange}
                  value={customer.city ?? ""}
                  error={!validationsParser.isValid("city")}
                  helperText={validationsParser.validationMessage("city")}
                  className={styles.textfield}
                />
                <FormControl className={styles.textfield} error={!validationsParser.isValid("regionId")}>
                  <InputLabel>Region *</InputLabel>
                  <Select
                    value={customer.regionId ?? ""}
                    name="regionId"
                    required
                    onChange={(event) => this._onChange(event as React.ChangeEvent<HTMLInputElement>)}
                  >
                    {_.map(regions, (r, idx) =>
                      <MenuItem value={r.id} key={idx}>{r.regionName}</MenuItem>
                    )}
                  </Select>
                  {!validationsParser.isValid("regionId") && <FormHelperText>{validationsParser.validationMessage("regionId")}</FormHelperText>}
                </FormControl>
                <AdvanceTextField
                  label="Postal Code"
                  name="zipPostalCode"
                  required
                  onChange={this._onZipPostalCodeChange}
                  value={customer.zipPostalCode ?? ""}
                  error={!validationsParser.isValid("zipPostalCode")}
                  helperText={validationsParser.validationMessage("zipPostalCode")}
                  className={styles.textfield}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={this._onSave}
              disabled={isFetching}
            >
              Save
            </Button>
            <Button
              onClick={this._onCancel}
              disabled={isFetching}
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        
        <BinaryChoiceDialog
          isOpen={this.state.confirmModalOpen}
          message="Customer already exists. Click Continue to create a duplicate Customer."
          trueText={"Continue"}
          falseText={"Cancel"}
          onClick={(value) => {
            if (value) {
              this._onSave();
            } else {
              this.setState({ confirmModalOpen: false });
            }
          }}
        />
      </>
    );
  }
}
export const EditCustomerModal = CustomerService.inject(_editCustomerModal);