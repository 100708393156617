import {
  React,
  _,
  bind,
  NumberFormat
} from "$Imports/Imports";

import {
  FeetInputField,
  IDataTableColumn,
  DataTable,
  ConfirmCancelModal,
  DisplayFormattedFeet
} from "$Imports/CommonComponents";

import {
  Commodity,
  EquipmentType,
  QuoteFreight,
} from "$Generated/api";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  createFilterOptions,
  FormControlLabel,
  IconButton,
  Card,
  CardHeader,
  CardActions,
  Checkbox
} from "$Imports/MaterialUIComponents";

import {
  Delete,
  Edit
} from "$Imports/MaterialUIIcons";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps,
} from "$State/QuoteEntryFreezerService";

import {
  IQuoteFreightServiceInjectedProps,
  QuoteFreightService,
  FreightTotalData
} from "$State/QuoteFreightFreezerService";

import {
  ValidationErrorParser
} from "$Utilities/ValidationErrorParser";

const styles: {
  nowrap: string;
  rowNumber: string;
  commodityTable: string;
  dimensionColumns: string;
  commodityColumn: string;
  switchColumn: string;
  updatedRow: string;
  updatedRowFirstChild: string;
  updatedRowLastChild: string;
  warningMessage: string;
  totalRow: string;
  weight: string;
  tableIcons: string;
  numOfPieces: string;
  parentAddEditDiv: string;
  addEditCard: string;
  addEditFreightDiv: string;
  containerDiv: string;
  lastAddedRow: string;
} = require("./AddEditQuoteCommodity.scss");

interface IAddEditQuoteCommodityProps {
  activeCommodities: Commodity[];
  activeEquipmentTypes: EquipmentType[];
}

type IAddEditQuoteCommodityBaseProps = IAddEditQuoteCommodityProps
  & IQuoteEntryServiceInjectedProps
  & IQuoteFreightServiceInjectedProps;

interface IAddEditQuoteCommodityState {
  isCancelModalOpen: boolean
}

const switchStyles = {
  root: {
    '& span.MuiSwitch-colorPrimary.Mui-disabled.Mui-checked': {
      color: "#707070"
    }
  }
};

class _AddEditQuoteCommodity extends React.Component<IAddEditQuoteCommodityBaseProps> {

  private stopsRef = React.createRef<HTMLDivElement>();

  state: IAddEditQuoteCommodityState = {
    isCancelModalOpen: false
  }

  @bind
  private _onSave(addEditQuoteFreight: QuoteFreight) {

    const {
      quoteStop,
      ...strippedLastRow
    } = addEditQuoteFreight;
    const lastDefaultRowPropValues: QuoteFreight = _.values(strippedLastRow);

    //filter out falsey values caused by onBlur functionality from some components
    if (!_.isEmpty(_.filter(lastDefaultRowPropValues, Boolean))) {
      this.setState({
        isCancelModalOpen: true
      });
    }
    else {
      this._onFreightSave();
    }
  }

  @bind
  private _focusStopInput() {
    this.stopsRef.current?.focus();
  }

  @bind
  private _onCancelContinue() {
    this._onFreightSave();
    this.setState({
      isCancelModalOpen: false
    });
  }

  @bind
  private _onCancelNo() {
    this.setState({
      isCancelModalOpen: false
    });
  }

  @bind
  private _onFreightTotalDataChange(freightTotalData: Partial<FreightTotalData>) {
    this.props.QuoteFreightService.updateFreightTotalData(freightTotalData);
  }

  @bind
  private _onQuoteFreightChange(quoteFreight: Partial<QuoteFreight>) {
    this.props.QuoteFreightService.updateAddEditQuoteFreight(quoteFreight);
  }

  @bind
  private _onQuoteFreightEdit(quoteFreightIndex: number) {
    this.props.QuoteFreightService.onQuoteFreightEdit(this.props.activeCommodities, quoteFreightIndex);
    this._focusStopInput();
  }

  @bind
  private _onFreightRemove(quoteFreightIndex: number) {
    this.props.QuoteFreightService.removeQuoteFreight(quoteFreightIndex);
  }

  @bind
  private _onAddFreightCommodity() {
    const { customerQuote } = QuoteEntryService.getState();
    const equipmentType = this.props.activeEquipmentTypes.find(et => et.id === customerQuote.equipmentTypeId);
    this.props.QuoteFreightService.addNewFreightCommodity(equipmentType?.tmEquipmentCode);
  }

  @bind
  private _onFreightCancel() {
    this.props.QuoteFreightService.cancelFreightModal();
  }

  @bind
  private _onFreightSave() {
    this.props.QuoteFreightService.saveFreightModal();
  }

  @bind
  private _onCommodityChanged(commodityItem: string) {
    const { activeCommodities } = this.props;
    const foundCommodity = _.find(activeCommodities, co => co.commodityName === commodityItem)!;

    this.props.QuoteFreightService.updateAddEditQuoteFreight({
      commodityId: foundCommodity.id,
      isStackable: foundCommodity.isStackable,
      isSideBySide: foundCommodity.isSideBySide,
      commodity: {
        commodityName: foundCommodity.commodityName
      }
    });
  }

  @bind
  private _onUpdateQuoteFreight() {
    const { customerQuote } = QuoteEntryService.getState();
    const equipmentType = this.props.activeEquipmentTypes.find(et => et.id === customerQuote.equipmentTypeId);
    this.props.QuoteFreightService.onUpdateQuoteFreightRow(equipmentType?.tmEquipmentCode);
  }

  @bind
  private _selectAllOnFocus(event: React.FocusEvent<HTMLInputElement>) {
    event.target.select();
  }

  render() {
    const {
      activeCommodities,
    } = this.props;

    const {
      currentQuoteFreight,
      updatedFreightRowData,
      freightTotalData,
      quoteFreightValidationErrors,
      isFreightModalOpen,
      addEditQuoteFreight
    } = this.props.QuoteFreightService.getState();

    const {
      isCancelModalOpen
    } = this.state;

    const validationsParserQuoteFreight = new ValidationErrorParser<QuoteFreight>(quoteFreightValidationErrors);

    const filterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (commodityName: string | null) => commodityName!
    });

    let lastRowIndex = 0;
    if (currentQuoteFreight) {
      lastRowIndex = currentQuoteFreight.length - 1;
    }

    let freightCommodities: QuoteFreight[];

    if (currentQuoteFreight) {
      freightCommodities = _.cloneDeep(currentQuoteFreight);
    }
    else {
      freightCommodities = [];
    }

    const totalRow: Array<IDataTableColumn<FreightTotalData>> = [
      {
        columnName: "rowCountTotal",
        columnFieldData: (d) => <div>{""}</div>,
        headerProps: {
          className: styles.rowNumber,
          style: { padding: "0px" }
        },
        headerValue: "",
        cellProps: {
          className: styles.totalRow
        }
      },
      {
        columnName: "commodityTotal",
        columnFieldData: (d) => <div>TOTAL</div>,
        headerProps: {
          className: styles.commodityColumn,
          style: { padding: "0px" }
        },
        headerValue: "",
        cellProps: {
          className: styles.totalRow
        }
      },
      {
        columnName: "numberOfPiecesTotal",
        columnFieldData: (d) => <div>{d.totalNumOfPieces}</div>,
        headerProps: {
          className: styles.numOfPieces,
          style: { padding: "0px" }
        },
        headerValue: "",
        cellProps: {
          className: styles.totalRow
        }
      },
      {
        columnName: "lengthTotal",
        columnFieldData: (d) => {
          return (
            freightTotalData.isOverdimensional ?
              <div className={styles.nowrap} >
                {"--"} <span className={styles.warningMessage} style={{ paddingLeft: "20px" }}>{"Over Dimensional / Requires Approval"}</span>
              </div>
              : <div><DisplayFormattedFeet value={freightTotalData.totalLength} /></div>
          )
        },
        headerProps: {
          className: styles.dimensionColumns,
          style: { padding: "0px" }
        },
        headerValue: "",
        cellProps: {
          className: styles.totalRow
        }
      },
      {
        columnName: "widthTotal",
        columnFieldData: (d) => {
          return (null)
        },
        headerProps: {
          className: styles.dimensionColumns,
          style: { padding: "0px" }
        },
        headerValue: "",
        cellProps: {
          className: styles.totalRow
        }
      },
      {
        columnName: "heightTotal",
        columnFieldData: (d) => {
          return (null)
        },
        headerProps: {
          className: styles.dimensionColumns,
          style: { padding: "0px" }
        },
        headerValue: "",
        cellProps: {
          className: styles.totalRow
        }
      },
      {
        columnName: "weightTotal",
        columnFieldData: (d) => <div className={styles.nowrap}>{`${d.totalWeight} lbs`}</div>,
        headerProps: {
          className: styles.weight,
          style: { padding: "0px" }
        },
        headerValue: "",
        cellProps: {
          className: styles.totalRow
        }
      },
      {
        columnName: "stackableTotal",
        columnFieldData: (d) => null,
        headerProps: {
          className: styles.switchColumn,
          style: { padding: "0px" }
        },
        headerValue: "",
        cellProps: {
          className: styles.totalRow
        }
      },
      {
        columnName: "sideBySideTotal",
        columnFieldData: (d) => null,
        headerProps: {
          className: styles.switchColumn,
          style: { padding: "0px" }
        },
        headerValue: "",
        cellProps: {
          className: styles.totalRow
        }
      },
      {
        columnName: "removeAddTotal",
        columnFieldData: (d) => <div style={{ background: "lightgrey" }}></div>,
        headerProps: {
          className: styles.tableIcons,
          style: { padding: "0px" }
        },
        headerValue: "",
        cellProps: {
          className: styles.totalRow
        }
      }
    ]

    const addedColumnsWithTotal: Array<IDataTableColumn<QuoteFreight>> = [
      {
        columnName: "rowCount",
        columnFieldData: (d, idx) => (
          <div>
            {idx + 1}
          </div>
        ),
        headerProps: {
          className: styles.rowNumber
        },
        headerValue: "",
        cellProps: (d, idx) => {
          let style = updatedFreightRowData?.indexBeingUpdated === idx ? styles.updatedRowFirstChild : idx === lastRowIndex ? styles.lastAddedRow : ""
          return { className: style }
        }
      },
      {
        columnName: "commodity",
        columnFieldData: (d, idx) => {
          return (
            <Autocomplete<string | null, false, true, false>
              options={activeCommodities.map(co => co.commodityName!)}
              getOptionLabel={(commodityOption: string | null) => commodityOption ?? ""}
              autoHighlight
              disabled={true}
              filterOptions={filterOptions}
              value={_.find(activeCommodities, co => co.id === d.commodityId)?.commodityName ?? d.commodity?.commodityName ?? ""}
              isOptionEqualToValue={(option, value) => {
                if (value === "") {
                  return false
                }
                return value === option
              }}
              disableClearable={true}
              renderInput={(params) =>
                <TextField
                  {...params}
                  style={{ margin: 0 }}
                  label=""
                  margin="normal"
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                  error={!validationsParserQuoteFreight.isValidDeep(`[${idx}].commodityId`)}
                  helperText={validationsParserQuoteFreight.validationMessageDeep(`[${idx}].commodityId`)}
                />}
            />
          )
        },
        headerProps: {
          className: styles.commodityColumn
        },
        headerValue: "Commodity",
        cellProps: (d, idx) => {
          let style = updatedFreightRowData?.indexBeingUpdated === idx ? styles.updatedRow : idx === lastRowIndex ? styles.lastAddedRow : ""
          return { className: style }
        }
      },
      {
        columnName: "numberOfPieces",
        columnFieldData: (d, idx) => {
          return (
            <NumberFormat
              allowNegative={false}
              decimalScale={0}
              label=""
              value={d?.numberOfPieces ?? ""}
              disabled={true}
              InputProps={{ disableUnderline: true }}
              format={'###'}
              customInput={TextField}
              error={!validationsParserQuoteFreight.isValidDeep(`[${idx}].numberOfPieces`)}
              helperText={validationsParserQuoteFreight.validationMessageDeep(`[${idx}].numberOfPieces`)}
            />
          )
        },
        headerProps: {
          className: styles.numOfPieces
        },
        headerValue: "# of Pieces",
        cellProps: (d, idx) => {
          let style = updatedFreightRowData?.indexBeingUpdated === idx ? styles.updatedRow : idx === lastRowIndex ? styles.lastAddedRow : ""
          return { className: style }
        }
      },
      {
        columnName: "length",
        columnFieldData: (d, idx) => {
          return (
            <FeetInputField
              label={""}
              error={!validationsParserQuoteFreight.isValidDeep(`[${idx}].length`)}
              helperText={validationsParserQuoteFreight.validationMessageDeep(`[${idx}].length`)}
              widthOverride={"35px"}
              disabled={true}
              InputProps={{ disableUnderline: true }}
              value={d?.length}
              onFeetValueChange={() => undefined}
            />
          )
        },
        headerValue: "Length",
        headerProps: {
          className: styles.dimensionColumns
        },
        cellProps: (d, idx) => {
          let style = updatedFreightRowData?.indexBeingUpdated === idx ? styles.updatedRow : idx === lastRowIndex ? styles.lastAddedRow : ""
          return { className: style }
        }
      },
      {
        columnName: "width",
        columnFieldData: (d, idx) => {
          return (
            <FeetInputField
              label={""}
              error={!validationsParserQuoteFreight.isValidDeep(`[${idx}].width`)}
              helperText={validationsParserQuoteFreight.validationMessageDeep(`[${idx}].width`)}
              widthOverride={"35px"}
              disabled={true}
              InputProps={{ disableUnderline: true }}
              value={d?.width}
              onFeetValueChange={() => undefined}
            />
          )
        },
        headerValue: "Width",
        headerProps: {
          className: styles.dimensionColumns
        },
        cellProps: (d, idx) => {
          let style = updatedFreightRowData?.indexBeingUpdated === idx ? styles.updatedRow : idx === lastRowIndex ? styles.lastAddedRow : ""
          return { className: style }
        }
      },
      {
        columnName: "height",
        columnFieldData: (d, idx) => {
          return (
            <FeetInputField
              label={""}
              error={!validationsParserQuoteFreight.isValidDeep(`[${idx}].height`)}
              helperText={validationsParserQuoteFreight.validationMessageDeep(`[${idx}].height`)}
              widthOverride={"35px"}
              disabled={true}
              InputProps={{ disableUnderline: true }}
              value={d?.height}
              onFeetValueChange={() => undefined}
            />
          )
        },
        headerValue: "Height",
        headerProps: {
          className: styles.dimensionColumns
        },
        cellProps: (d, idx) => {
          let style = updatedFreightRowData?.indexBeingUpdated === idx ? styles.updatedRow : idx === lastRowIndex ? styles.lastAddedRow : ""
          return { className: style }
        }
      },
      {
        columnName: "weight",
        columnFieldData: (d, idx) => {
          return (
            <NumberFormat
              allowNegative={false}
              decimalScale={0}
              label=""
              value={d?.weight ?? ""}
              disabled={true}
              InputProps={{ disableUnderline: true }}
              customInput={TextField}
              suffix=" lbs"
              error={!validationsParserQuoteFreight.isValidDeep(`[${idx}].weight`)}
              helperText={validationsParserQuoteFreight.validationMessageDeep(`[${idx}].weight`)}
            />
          )
        },
        headerProps: {
          className: styles.weight
        },
        headerValue: "Weight",
        cellProps: (d, idx) => {
          let style = updatedFreightRowData?.indexBeingUpdated === idx ? styles.updatedRow : idx === lastRowIndex ? styles.lastAddedRow : ""
          return { className: style }
        }
      },
      {
        columnName: "stackable",
        columnFieldData: (d, idx) => {
          return d?.isStackable ? "Yes" : "No";
        },
        headerProps: {
          className: styles.switchColumn
        },
        headerValue: "Stackable",
        cellProps: (d, idx) => {
          let style = updatedFreightRowData?.indexBeingUpdated === idx ? styles.updatedRow : idx === lastRowIndex ? styles.lastAddedRow : ""
          return { className: style }
        }
      },
      {
        columnName: "sideBySide",
        columnFieldData: (d, idx) => {
          return d?.isSideBySide ? "Yes" : "No";
        },
        headerProps: {
          className: styles.switchColumn
        },
        headerValue: "Side by Side",
        cellProps: (d, idx) => {
          let style = updatedFreightRowData?.indexBeingUpdated === idx ? styles.updatedRow : idx === lastRowIndex ? styles.lastAddedRow : ""
          return { className: style }
        }
      },
      {
        columnName: "removeAdd",
        columnFieldData: (d, idx) => {
          return (
            <div className={styles.nowrap} >
              <IconButton
                size="small"
                onClick={() => this._onQuoteFreightEdit(idx)}
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={() => this._onFreightRemove(idx)}
                size="small"
              >
                <Delete />
              </IconButton>
            </div>
          )
        },
        headerProps: {
          className: styles.tableIcons
        },
        headerValue: "",
        cellProps: (d, idx) => {
          let style = updatedFreightRowData?.indexBeingUpdated === idx ? styles.updatedRowLastChild : idx === lastRowIndex ? styles.lastAddedRow : ""
          return { className: style }
        }
      }
    ];

    const freightTotalDataArr: FreightTotalData[] = new Array<FreightTotalData>(freightTotalData);

    return (
      <>
        <Dialog
          open={isFreightModalOpen}
          maxWidth="lg"
        >
          <DialogTitle>
            Commodity Entry
          </DialogTitle>
          <DialogContent className={styles.commodityTable}>
            {freightCommodities.length !== 0 &&
              <>
                <DataTable
                  columns={addedColumnsWithTotal}
                  data={freightCommodities}
                />
                <DataTable
                  columns={totalRow}
                  data={freightTotalDataArr}
                />
              </>}
            <div className={styles.parentAddEditDiv}>
              <Card className={styles.addEditCard}>
                <CardHeader title="Add/Edit Freight" style={{ padding: "5px" }} />
                <CardActions style={{ flexWrap: "wrap" }}>
                  <div className={styles.addEditFreightDiv}>

                    <div className={styles.containerDiv} style={{ display: "flex", paddingRight: "0px", paddingLeft: "0px" }}>
                      <div className={styles.nowrap}>Commodity</div>
                      <Autocomplete<string | null, false, true, false>
                        options={activeCommodities.map(co => co.commodityName!)}
                        getOptionLabel={(commodityOption: string | null) => commodityOption ?? ""}
                        autoHighlight
                        style={{ width: "200px", paddingLeft: "10px", paddingRight: "10px" }}
                        value={_.find(activeCommodities, co => co.id === addEditQuoteFreight?.commodityId)?.commodityName ?? addEditQuoteFreight?.commodity?.commodityName ?? ""}
                        onChange={(event, value) => { this._onCommodityChanged(value) }}
                        isOptionEqualToValue={(option, value) => {
                          if (value === "") {
                            return false
                          }
                          return value === option
                        }}
                        disableClearable={true}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            style={{ marginTop: "-5px" }}
                            label=""
                            autoFocus
                            margin="normal"
                            error={!validationsParserQuoteFreight.isValid(`commodityId`)}
                            helperText={validationsParserQuoteFreight.validationMessage(`commodityId`)}
                          />
                        }
                      />
                    </div>
                    <div className={styles.containerDiv} style={{ display: "flex" }}>
                      <div className={styles.nowrap}># of Pieces</div>
                      <NumberFormat
                        allowNegative={false}
                        decimalScale={0}
                        onFocus={this._selectAllOnFocus}
                        label=""
                        value={addEditQuoteFreight?.numberOfPieces ?? ""}
                        isAllowed={(value) => (value?.floatValue ?? 0) <= 999}
                        style={{ width: "50px", marginTop: "-5px", paddingLeft: "5px" }}
                        customInput={TextField}
                        onValueChange={(value) => this._onQuoteFreightChange({ numberOfPieces: value.floatValue })}
                        error={!validationsParserQuoteFreight.isValid(`numberOfPieces`)}
                        helperText={validationsParserQuoteFreight.validationMessage(`numberOfPieces`)}
                      />
                    </div>

                    <div className={styles.containerDiv}>
                      <div>Length</div>
                      <FeetInputField
                        label={""}
                        style={{ marginTop: "-10px" }}
                        error={!validationsParserQuoteFreight.isValid(`length`)}
                        helperText={validationsParserQuoteFreight.validationMessage(`length`)}
                        widthOverride={"35px"}
                        value={addEditQuoteFreight?.length}
                        onFeetValueChange={(value) => this._onQuoteFreightChange({ length: value })}
                      />
                    </div>
                    <div className={styles.containerDiv}>
                      <div>Width</div>
                      <FeetInputField
                        label={""}
                        error={!validationsParserQuoteFreight.isValid(`width`)}
                        helperText={validationsParserQuoteFreight.validationMessage(`width`)}
                        widthOverride={"35px"}
                        value={addEditQuoteFreight?.width}
                        onFeetValueChange={(value) => this._onQuoteFreightChange({ width: value })}
                      />
                    </div>
                    <div className={styles.containerDiv}>
                      <div>Height</div>
                      <FeetInputField
                        label={""}
                        error={!validationsParserQuoteFreight.isValid(`height`)}
                        helperText={validationsParserQuoteFreight.validationMessage(`height`)}
                        widthOverride={"35px"}
                        value={addEditQuoteFreight?.height}
                        onFeetValueChange={(value) => this._onQuoteFreightChange({ height: value })}
                      />
                    </div>
                    <div className={styles.containerDiv}>
                      <div>Weight</div>
                      <div style={{ display: "inline-flex" }}>
                        <NumberFormat
                          allowNegative={false}
                          decimalScale={0}
                          label=""
                          value={addEditQuoteFreight?.weight ?? ""}
                          customInput={TextField}
                          onFocus={this._selectAllOnFocus}
                          style={{ width: "80px" }}
                          onValueChange={(value) => this._onQuoteFreightChange({ weight: value.floatValue })}
                          error={!validationsParserQuoteFreight.isValid(`weight`)}
                          helperText={validationsParserQuoteFreight.validationMessage(`weight`)}
                        />
                        <div style={{ marginTop: "8px" }}>
                          lbs
                        </div>
                      </div>
                    </div>

                    <div className={styles.containerDiv}>
                      Stackable
                      <FormControlLabel
                        control={
                          (
                            <Checkbox
                              color="primary"
                              size="small"
                              checked={addEditQuoteFreight?.isStackable ?? false}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  this._onQuoteFreightChange({ isStackable: !addEditQuoteFreight?.isStackable })
                                }
                              }}
                              onChange={(event, value) => this._onQuoteFreightChange({ isStackable: value })}
                            />
                          )
                        }
                        label=""
                        labelPlacement="start"
                      />
                    </div>
                    <div className={styles.containerDiv}>
                      Side by Side
                      <FormControlLabel
                        control={
                          (
                            <Checkbox
                              color="primary"
                              size="small"
                              checked={addEditQuoteFreight?.isSideBySide ?? false}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  this._onQuoteFreightChange({ isSideBySide: !addEditQuoteFreight?.isSideBySide })
                                }
                              }}
                              onChange={(event, value) => this._onQuoteFreightChange({ isSideBySide: value })}
                            />
                          )
                        }
                        label=""
                        labelPlacement="start"
                      />
                    </div>

                    {updatedFreightRowData?.isRowBeingEdited ?
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={() => this._onUpdateQuoteFreight()}
                      >
                        Update
                      </Button>
                      : <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={() => this._onAddFreightCommodity()}
                      >
                        Add
                      </Button>}
                  </div>
                </CardActions>
              </Card>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this._onSave(addEditQuoteFreight)}
            >
              Done
            </Button>
            <Button
              variant="outlined"
              onClick={() => this._onFreightCancel()}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <ConfirmCancelModal
          isOpen={isCancelModalOpen}
          onCancelYes={this._onCancelContinue}
          onCancelNo={this._onCancelNo}
          message={"You have unsaved input that will be deleted - are you sure you want to continue?"}
        />
      </>
    );
  }
}

export const AddEditQuoteCommodity = QuoteEntryService.inject(
  QuoteFreightService.inject(
    _AddEditQuoteCommodity
  )
);


