
import {
  React,
  bind,
  moment
} from "$Imports/Imports";

import {
  QuoteEntryService,
  IQuoteEntryServiceInjectedProps
} from "$State/QuoteEntryFreezerService";

import {
  StateService,
  IStateServiceInjectedProps
} from "$State/RegionFreezerService";

import {
  CustomerService,
  ICustomerServiceInjectedProps
} from "$State/CustomerFreezerService";

import {
  CustomerDetailService,
  ICustomerDetailServiceInjectedProps
} from "$State/CustomerDetailFreezerService";

import {
  Card,
  Button
} from "$Imports/MaterialUIComponents";

import {
  CommodityDataEntryView
} from "../CustomerQuoteView/CommodityDataEntryView";

import {
  CustomerInfo
} from "../CustomerQuoteView/CustomerInfo";

import {
  DisplayFormattedDatetime,
  DisplayFormattedNumber
} from "$Imports/CommonComponents";

import {
  CustomerPortalRateEngineResult
} from "$Generated/api";

import {
  CommodityService,
  ICommodityServiceInjectedProps
} from "$State/CommodityFreezerService";

import {
  TarpService,
  ITarpServiceInjectedProps
} from "$State/TarpFreezerService";

import {
  EquipmentTypeService,
  IEquipmentTypeServiceInjectedProps
} from "$State/EquipmentTypeFreezerService";

import { getCompanyLogo } from "../../utilities/companyUtil";

const styles: {
  printQuoteCard: string,
  aboveSum: string,
  sum: string,
  customerCard: string,
  logo: string,
  printButton: string,
  disclaimerText: string,
  expired: string
} = require("./PrintQuoteView.scss");

interface IPrintQuoteViewBaseProps {
  quoteId?: string;
}

type IPrintQuoteViewProps = IPrintQuoteViewBaseProps
  & IQuoteEntryServiceInjectedProps
  & ICommodityServiceInjectedProps
  & ICustomerServiceInjectedProps
  & IStateServiceInjectedProps
  & ICustomerDetailServiceInjectedProps
  & ITarpServiceInjectedProps
  & IEquipmentTypeServiceInjectedProps;

class _PrintQuoteView extends React.Component<IPrintQuoteViewProps> {

  async componentDidMount() {
    await this.props.QuoteEntryService.fetchQuoteById(this.props.quoteId);
    this.props.commodityService.fetchActiveCommodities(true);
    await this.props.customerService.getCustomerByTruckMateId(true);
    this.props.regionService.fetchStates();
    this.props.TarpService.fetchTarps();
    this.props.equipmentTypeService.fetchEquipmentTypes();

    const {currentCustomer} = this.props.customerService.getState();
    if (currentCustomer && currentCustomer.id) {
      this.props.customerDetailService.fetchCustomerContacts(currentCustomer.id);
    }
  }

  @bind
  private printQuote() {
    window.print();
  }

  render() {
    const {
      customerQuote,
      isOverdimensional
    } = this.props.QuoteEntryService.getState();

    const {
      regionFetchResults
    } = this.props.regionService.getState();

    const {
      currentCustomer
    } = this.props.customerService.getState();

    const {
      contactsFetchResults
    } = this.props.customerDetailService.getState();

    const {
      tarpFetchResults
    } = this.props.TarpService.getState();

    const {
      equipmentTypeFetchResults
    } = this.props.equipmentTypeService.getState();

    let rateEngineResults: CustomerPortalRateEngineResult = {}
    if (customerQuote.rateEngineResults) {
      rateEngineResults = JSON.parse(customerQuote.rateEngineResults);
    }
    
    const tarps = tarpFetchResults.data ?? [];
    const tarp = tarps.find(t => t.id === customerQuote.tarpId);
    const equipmentTypes = equipmentTypeFetchResults.data ?? [];
    const equipmentType = equipmentTypes.find(t => t.id === customerQuote.equipmentTypeId);

    const contactNumber = currentCustomer?.salesAgent?.phoneNumber && currentCustomer?.salesAgent?.phoneNumber !== "" ? currentCustomer?.salesAgent?.phoneNumber : "800-590-2101";

    return (
      <Card className={styles.printQuoteCard}>
        <img alt="" src={getCompanyLogo(customerQuote.companyId)} className={styles.logo} />
        <Button
          color="primary"
          variant="contained"
          onClick={this.printQuote}
          className={styles.printButton}
        >
          Print
        </Button>
        <Card className={styles.customerCard}>
          <CustomerInfo
            currentQuote={customerQuote}
            currentCustomer={currentCustomer}
            contacts={contactsFetchResults.data ?? []}
            regions={regionFetchResults.data ?? []}
            printView={true}
          />
        </Card>
        <div className={styles.disclaimerText}>
          {isOverdimensional ? "Freight is over dimensional. A Kaiser representative will contact you as soon as possible to provide a quote."
            : `This is an estimated rate. Your Kaiser representative will contact you to confirm pricing and finalize this shipment within the next business day. If this requires action sooner, please reach out to your Kaiser representative at ${contactNumber}.`}
        </div>
        <table>
          <tbody>
            <tr>
              <td>Quote Number</td>
              <td>EQ{customerQuote.quoteNumber}</td>
            </tr>
            <tr>
              <td>Quote Status</td>
              <td className={customerQuote.quoteStatus === "Expired" ? styles.expired : undefined}>{customerQuote.quoteStatus}</td>
            </tr>
            <tr>
              <td>PO #</td>
              <td>{customerQuote.poNumber}</td>
            </tr>
            <tr>
              <td>Quote Date</td>
              <td><DisplayFormattedDatetime value={customerQuote.quoteDate} formatString={'MM/DD/YYYY'} /></td>
            </tr>
            <tr>
              <td>Expiration Date</td>
              <td className={customerQuote.quoteStatus === "Expired" ? styles.expired : undefined}><DisplayFormattedDatetime value={customerQuote.expirationDate} formatString={'MM/DD/YYYY'} /></td>
            </tr>
            <tr>
              <td>Shipper Zipcode</td>
              <td>{customerQuote.shipperZipPostalCode}</td>
            </tr>
            <tr>
              <td>Consignee Zipcode</td>
              <td>{customerQuote.consigneeZipPostalCode}</td>
            </tr>
            <tr>
              <td colSpan={2} style={{ textAlign: "left" }}>
                <CommodityDataEntryView
                  printMode
                  hasEquipmentType={!!customerQuote.equipmentTypeId}
                />
              </td>
            </tr>
            {customerQuote.description &&
              <tr>
                <td>Description</td>
                <td>{customerQuote.description}</td>
              </tr>
            }
            <tr>
              <td>Shipper Schedule</td>
              <td>
                <DisplayFormattedDatetime value={customerQuote.shipperStartDate} formatString={'MM/DD/YYYY'} />
                {customerQuote.shipperEndDate ?
                  <>
                    {' to '}
                    <DisplayFormattedDatetime value={customerQuote.shipperEndDate} formatString={"MM/DD/YYYY"} />
                  </> : ""}
              </td>
            </tr>
            {customerQuote.isShipperAppointmentRequired &&
              <tr>
                <td>Shipper Hardtime</td>
                <td>{moment(customerQuote.shipperHardTime, 'HH:mm:ss').format('hh:mm A')}</td>
              </tr>
            }
            <tr>
              <td>Consignee Schedule</td>
              <td>
                <DisplayFormattedDatetime value={customerQuote.consigneeStartDate} formatString={'MM/DD/YYYY'} />
                {customerQuote.consigneeEndDate ?
                  <>
                    {' to '}
                    <DisplayFormattedDatetime value={customerQuote.consigneeEndDate} formatString={"MM/DD/YYYY"} />
                  </> : ""}
              </td>
            </tr>
            {customerQuote.isConsigneeAppointmentRequired &&
              <tr>
                <td>Consignee Hardtime</td>
                <td>{moment(customerQuote.consigneeHardTime, 'HH:mm:ss').format('hh:mm A')}</td>
              </tr>
            }
            <tr>
              <td>Expedited Shipping</td>
              <td>{customerQuote.isExpedited ? "True" : "False"}</td>
            </tr>
            <tr>
              <td>Equipment Type</td>
              <td>{equipmentType?.name ?? "N/A"}</td>
            </tr>
            <tr>
              <td>Tarp Type</td>
              <td>
                {tarp?.tarpName ?? "N/A"}
              </td>
            </tr>
            <tr>
              <td>Declared Value</td>
              <td>
                <DisplayFormattedNumber value={customerQuote.declaredValue} formatString={"$0,000"} />
              </td>
            </tr>
            <tr>
              <td>Mileage</td>
              <td>{customerQuote.mileage}</td>
            </tr>
            {customerQuote.notes &&
              <>
                <tr>
                  <td>Notes</td>
                  <td>{customerQuote.notes}</td>
                </tr>
              </>
            }
            {!isOverdimensional &&
              <>
                <tr>
                  <td>Line Haul</td>
                  <td>
                    <DisplayFormattedNumber
                      value={rateEngineResults.lineHaulRate}
                      formatString={"$0,0.00"}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Tarp</td>
                  <td>
                    <DisplayFormattedNumber
                      value={rateEngineResults.tarpRate}
                      formatString={"$0,0.00"}
                    />
                  </td>
                </tr>
                {rateEngineResults.locationFee ?
                <tr>
                  <td>Location Fee</td>
                  <td>
                    <DisplayFormattedNumber
                      value={rateEngineResults.locationFee}
                      formatString={"$0,0.00"}
                    />
                  </td>
                </tr> : ""}
                <tr>
                  <td className={styles.aboveSum}>
                    Fuel Surcharge
                    <>
                      {" ("}
                      <DisplayFormattedNumber
                        value={rateEngineResults.fuelSurchargePercentage}
                        formatString={"(0.00%)"}
                      />
                      {")"}
                    </>
                  </td>
                  <td className={styles.aboveSum}>
                    <DisplayFormattedNumber
                      value={rateEngineResults.fuelSurcharge}
                      formatString={"$0,0.00"}
                    />
                  </td>
                </tr>
                <tr>
                  <td className={styles.sum}>Rate</td>
                  <td className={styles.sum}>
                    <DisplayFormattedNumber
                      value={rateEngineResults.highTotalRate}
                      formatString={"$0,0.00"}
                    />
                  </td>
                </tr>
              </>
            }
          </tbody>
        </table>
      </Card>
    );
  }

}

export const PrintQuoteView = QuoteEntryService.inject(
  CommodityService.inject(
    CustomerService.inject(
      StateService.inject(
        CustomerDetailService.inject(
          TarpService.inject(
            EquipmentTypeService.inject(
              _PrintQuoteView
            )
          )
        )
      )
    )
  )
);

